import React, {Component} from 'react'







class Footer extends Component {




    render() {
        return (
            <footer className="Footer">
                
                <div>
                    
                </div>

                <div>Sva prava su pridržana © 2020. Up Rent </div>

            </footer>
        );
    } 
    

    

}

export default Footer;
