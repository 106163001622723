import React, {Component} from 'react'
import axios from 'axios'
import {storage} from '../../../firebase/firebase'




class Form extends Component {


    state={
        image: '', 							// slika koja se ucita kada se odabere 
		url: '', 								// url ucitane slike nakon sta se uploada na firebase
		percentage: '', 						// postotak koji se prikaze prilikom postavljanja slike - da korisnik zna koliko treba 
        naziv: '',
        sifra: '',
        pogon: '',
        radnaVisina: null,
        radniDoseg: null,
        podizniKapacitet: null,
        dimenzije: '',
        platforma: '',
        tezina: '',
        kategorija: '',
        podkategorija: '',
        jacina: '',
        pdf: ''
    }

    inputChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })

        console.log(this.state.radniDoseg)

    }

    fileHandler = (e) => {

        if(e.target.files[0]) {											// postavljanje odabrane slike kao image state
            const image = e.target.files[0];
            this.setState(() => ({image}));
		}

    }

    uploadHandler = () => {

		
		
		const {image} = this.state;										//uploadanje slike na firebase
		const uploadTask = storage.ref(`${image.name}`).put(image);
		uploadTask.on('state_changed', 
		(snapshot) => {
				let percentage = Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100)
				this.setState({percentage: percentage})
		}, 
		(error) => { 													// error function
			console.log(error);
		}, 
		() => {
																		// complete funtion
		storage.ref('').child(image.name).getDownloadURL().then(url => {
			this.setState({url});
		})
		
		});  
    }

    sendHandler = () => {

        if(
            (this.state.naziv !== '') //&&
          //  (this.state.sifra !== '')// &&
           // (this.state.radnaVisina !== '') &&
           // (this.state.podizniKapacitet !== '') &&
           // (this.state.dimenzije !== '') &&
            //(this.state.tezina !== '') &&
           // (this.state.kategorija !== '') //&&
          //  (this.state.podkategorija !== '') 
        ) {



            if(this.state.url) {

                axios.post('https://webrentsite.firebaseio.com/prodajaPodizne/skaraste/items.json', {

                img: this.state.url,
                naziv: this.state.naziv,
                sifra: this.state.sifra,
                link: this.state.naziv.replace(/ /g,'').toLowerCase(),
                pogon: this.state.pogon ? this.state.pogon.replace(/ /g,'') : '',
                radnaVisina: this.state.radnaVisina ? parseFloat(this.state.radnaVisina).toFixed(2) : '',
                radniDoseg: this.state.radniDoseg ? parseFloat(this.state.radniDoseg).toFixed(2) : '',
                podizniKapacitet: this.state.podizniKapacitet ? parseFloat(this.state.podizniKapacitet).toFixed(2) : '',
                dimenzije: this.state.dimenzije,
                pdf: this.state.pdf ? this.state.pdf : '',
              //  platforma: this.state.platforma ? this.state.platforma : '',
                tezina: this.state.tezina ? parseFloat(this.state.tezina).toFixed(2) : '',
                kategorija: 'prodajaPodizne',
                podkategorija: 'skaraste',
                brand: 'Almacrawler',
             //   jacina: this.state.jacina ? this.state.jacina : '',


                })
                .then(response => {

                    console.log(response.data)

                })
                .catch(error => {
            
                });  

                this.setState({
                    image: '', 							 
		            url: '', 								
		            percentage: '', 
                    naziv: '',
                    sifra: '',
                    pogon: '',
                    radnaVisina: '',
                    radniDoseg: '',
                    podizniKapacitet: '',
                    dimenzije: '',
                    platforma: '',
                    tezina: '',
                    kategorija: '',
                    podkategorija: '',
                    brand: '',
                    jacina: '',
                    pdf:''
                })

            }
            


        }
        

    }


    render() {
        return (
            <div className="Main">

                <div className="SendWrap">
                <img src={this.state.url ? '' + this.state.url +'' : 'http://via.placeholder.com/100x150'} alt="Uploaded file"/>
				<br />
				<input type="file" 
				onChange={this.fileHandler}
				name="file"
				className="inputfile"
				id="file"
				/>
				
				<br />
				<p style={{textAlign: 'center', display: this.state.percentage ? 'block' : 'none'}}>Učitavanje slike: {this.state.percentage}%</p>
				<label className="sendItems" onClick={this.uploadHandler} style={{display: this.state.image ? 'block' : 'none', marginLeft: 'auto', marginRight: 'auto'}}>Postavi odabranu sliku</label>


                <input type="text" onChange={(e) => this.inputChange(e)} name="naziv" value={this.state.naziv} placeholder="Naziv"/> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="pdf" value={this.state.pdf} placeholder="PDF"/> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="sifra" value={this.state.sifra} placeholder="Šifra"/> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="pogon" value={this.state.pogon} placeholder="Pogon" /> <br />

                <input type="number" onChange={(e) => this.inputChange(e)} name="radnaVisina" value={this.state.radnaVisina} placeholder="Radna visina" /> <br />

                <input type="number" onChange={(e) => this.inputChange(e)} name="radniDoseg" value={this.state.radniDoseg} placeholder="Radni doseg" /> <br />

                <input type="number" onChange={(e) => this.inputChange(e)} name="podizniKapacitet" value={this.state.podizniKapacitet} placeholder="Podizni kapacitet" /> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="dimenzije" value={this.state.dimenzije} placeholder="Dimenzije" /> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="platforma" value={this.state.platforma} placeholder="Platforma" /> <br />

                <input type="number" onChange={(e) => this.inputChange(e)} name="tezina" value={this.state.tezina} placeholder="Težina" /> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="kategorija" value={this.state.kategorija} placeholder="Kategorija" /> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="podkategorija" value={this.state.podkategorija} placeholder="Podkategorija" /> <br />

                <input type="text" onChange={(e) => this.inputChange(e)} name="jacina" value={this.state.jacina} placeholder="Jačina" /> <br />


                <div className="sendItems" onClick={this.sendHandler}>POŠALJI</div>
                </div>

            </div>
        );
    } 
    

    

}

export default Form;
