import React, {Component} from 'react'




import TransportiImg from '../../../assets/images/TransportiImg.jpg'


class Transporti extends Component {




    render() {
        return (
            <div className="Transporti">
                
                <div style={{backgroundImage:"url(" + TransportiImg + ")"}} className="TransportiCover"></div>

                <div className="TransportiText">
                    <h1>Transporti</h1>
                    <p>
                    Specijalizirani smo za utovar, prijevoz i istovar radnih strojeva i platformi po dogovorenoj cijeni i u dogovorenom vremenu. Naša kamionska flota također nam omogućuje fleksibilnost pri pružanju usluga vanjskog prijevoza. Za sva pitanja slobodno se obratite na broj: <a href="+385913320040" style={{textDecoration: 'none', color: 'black'}} > 091 332 0040</a>
                    </p>
                </div>


            </div>
        );
    } 
    

    

}

export default Transporti;
