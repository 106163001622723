import React, {Component} from 'react'



import sinisa from '../../../assets/images/sinisa.jfif';
import ratka from '../../../assets/images/ratka.jpg';
import marcijan from '../../../assets/images/marcijan.jpg';
import luka from '../../../assets/images/luka.jpg';

import OnamaImg from '../../../assets/images/build.jpg';
import email from '../../../assets/images/email.svg';
import phone from '../../../assets/images/phone.svg';

class Onama extends Component {


    state={
        people: [
            {img: sinisa, name: 'Siniša', titula: 'Direktkor', text: 'Bogato radno iskustvo u iznajmljivanju radnih Platformi od 2001 godine. Preko 3000 zadovoljnih kupaca dokaz je o profesionalnosti i fleksibilnosti.', mail: 'sinisa@uprent.hr', phone: '+385 91 332 0040'},

            {img: ratka, name: 'Ratka Iva', titula: 'Računovodstvo i financije', text: '', mail: 'iva@uprent.hr', phone: '+385 99 300 3111'},

            {img: '', name: 'Mirna', titula: 'Računovodstvo i financije', text: '', mail: 'info@uprent.hr', phone: '+385 91 332 9005'},

            {img: marcijan, name: 'Marcijan', titula: 'Regionalni direktor za  najam', text: 'Gospodin Marcijan ima višegodišnje iskustvo u servisiranju radnih platformi HAULOTTE, GENIE, JLG, MANITOU', mail: 'servis@uprent.hr', phone: '+385 91 332 9000'},

            {img: luka, name: 'Luka', titula: 'Referent za prodaju novih strojeva', text: '', mail: 'prodaja@uprent.hr', phone: '+385 91 332 9006'},

            {img: '', name: 'Tomislav Vranaričić', titula: 'Glavni serviser', text: 'Gospodin Vranaričić ima višegodišnje iskustvo u servisiranju radnih platformi HAULOTTE, GENIE, JLG, MANITOU', mail: 'tomislav@uprent.hr', phone: '+385 91 332 9001'},

            {img: '', name: 'Marijan', titula: 'Servis', text: '', mail: 'marijan@uprent.hr', phone: '+385 91 332 9002'},

            {img: '', name: 'Valentin', titula: 'Servis', text: '', mail: 'valentin@uprent.hr', phone: '+385 91 332 9004'},

            {img: '', name: 'Miroslav', titula: 'Prijevozi', text: '', mail: 'prijevoz@uprent.hr', phone: '+385 91 332 0053'}
        ]
    }


    render() {

        
        let people = this.state.people.map(p => {
            return (
                <div className="OnamaBox" key={p.name}>
                    <img src={p.img} style={{display: (p.img !== '' )? 'block' : 'none'}} />
                    <h3 style={{display: p.name ? 'block' : 'none'}}>{p.name}</h3>
                    <h4 style={{display: p.titula ? 'block' : 'none'}}>{p.titula}</h4>
                    <p style={{display: p.text ? 'block' : 'none'}}>{p.text}</p>
                    <p style={{display: p.phone ? 'block' : 'none'}}><img src={phone} /><a href={'tel:' + p.phone}> {p.phone} </a></p>
                    <p style={{display: p.mail ? 'block' : 'none'}}><img src={email} /> <a href={"mailto:" + p.mail + ""} >{p.mail}</a></p>
                </div>
            )
        })


        return (
            <div className="Onama">
                
                <div style={{backgroundImage:"url(" + OnamaImg + ")"}} className="OnamaCover"></div>

                <div className="OnamaText">

                    <h1> O nama</h1>
                    <p>
                    UpRent je brand poduzeća specijaliziranog za NAJAM, PRODAJ, SERVIS, TRANSPORT radnih platformi, viličara, auto košara te druge opreme za siguran rad na visini. Ekskluzivni smo zastupnici za HAULOTTE, FARAONE, CO.ME.T., DIECI, FIORENTINI, CIFA ZOOMLION Europa, EASYLIFT

                    UpRent je sa svojim dugogodišnjim managementom od 2001. apsolutni lider u  promoviranju i kulturi pružanja takvih usluga u Republici Hrvatskoj te edukaciji naših klijenata za siguran rad na visini.
                    </p>

                    <p>
                    Našim klijentima na raspolaganju su profesionalni timovi za: SERVIS, PRODAJU, TRANSPORT I ADMINISTRACIJU koji će u najkraćem roku dati sve odgovore i na najzahtjevnije probleme.

                    Naš tim suradnika i kolega je pažljivo izabran da bi pružili najbolju uslugu u najmu radnih platformi te servisiranju i transportu.

                    Osiguravamo najbolju uslugu najma te servis svih radnih platformi i teleskopskih viličara sa naglaskom na svjetske brendove kao što su JLG, GENIE, HAULOTTE, MANITOU, CO.ME.T., FARAONE, DIECI, FIORENTINI, UPRIGH…. i mnogi drugi
                    </p>

                </div>

                <div className="OnamaBoxes">

                    <h1>Naš tim</h1>

                    <div className="OnamaBoxesDiv">
                        {people}
                    </div>

                </div>

            </div>
        );
    } 
    

    

}

export default Onama;
