import React, {Component} from 'react'
import axios from 'axios'


import down from '../../../assets/images/down.svg'
import up from '../../../assets/images/up.svg'

class Prodaja extends Component {



    state={
        podizne:[],
        teleskopskiVilicari: [],
        iskopi: [],
        ciscenje: [],
        vilicari: [],
        all: [],

        brand: [
            {id: 0, value: 'Comet', displayValue: 'CO.ME.T', active: false},
            {id: 1, value: 'Dieci', displayValue: 'Dieci', active: false},
            {id: 2, value: 'Fiorentini', displayValue: 'Fiorentini', active: false},
            {id: 3, value: 'Haulotte', displayValue: 'Haulotte', active: false},
            {id: 4, value: 'Kato Imer', displayValue: 'Kato Imer', active: false},
            {id: 5, value: 'Zoomlion', displayValue: 'Zoomlion', active: false},
            {id: 6, value: 'Almacrawler', displayValue: 'Almacrawler', active: false},
            {id: 7, value: 'EasyLift', displayValue: 'Easy Lift', active: false}
        ],

        kategorije: [
            {id: 0, value: 'prodajaPodizne', displayValue: 'Podizne platforme', active: (this.props.katNUm === 0) ? true : false, podkategorije:[
                {id: 0, value: 'stupne', displayValue: 'Stupne platforme', active: false},
                {id: 1, value: 'skaraste', displayValue: 'Škaraste platforme', active: false},
                {id: 2, value: 'zglobne', displayValue: 'Zglobne platforme', active: false},
                {id: 3, value: 'teleskopskePlatforme', displayValue: 'Teleskopske platforme', active: false},
                {id: 4, value: 'pauk', displayValue: 'Pauk', active: false}
            ]},

            {id: 1, value: 'prodajaTeleskopskiVilicari', displayValue: 'Teleskopski viličari', active: false, podkategorije:[
                {id: 5, value: 'teleskopskiVilicar', displayValue: 'Teleskopski viličar', active: false},
            ]},

            {id: 2, value: 'prodajaVilicar', displayValue: 'Viličari', active: (this.props.katNUm === 2) ? true : false, podkategorije:[
                {id: 6, value: 'nisko', displayValue: 'Nisko podizanje', active: false},
                {id: 7, value: 'visoko', displayValue: 'Visoko podizanje', active: false},
                {id: 8, value: 'cheoni', displayValue: 'Čeoni', active: false},    
            ]},

            {id: 3, value: 'prodajaIskopi', displayValue: 'Strojevi za iskop', active: false, podkategorije:[
                {id: 9, value: 'rovokopaci', displayValue: 'Rovokopači', active: false},
                {id: 10, value: 'utovarivaci', displayValue: 'Utovarivači', active: false},
                {id: 11, value: 'damper', displayValue: 'Mini damper (istovarivač)', active: false},
                {id: 12, value: 'agregator', displayValue: 'Agregatori', active: false},
                {id: 13, value: 'kompresor', displayValue: 'Kompresori', active: false}
            ]},

            {id: 4, value: 'prodajaCiscenje', displayValue: 'Oprema za čišćenje', active: (this.props.katNUm === 4) ? true : false, podkategorije: [
                {id: 14, value: 'cistilice', displayValue: 'Čistilice', active: false},
                {id: 15, value: 'perilica', displayValue: 'Perilice', active: false}
            ]},

            {id: 5, value: 'autokosara', displayValue: 'Autokošare', active: (this.props.katNUm === 1) ? true : false},

        ],

        pogon: [
            {id: 0, value: 'Diesel', displayValue: 'Diesel', active: false},
            {id: 1, value: 'Električni', displayValue: 'Električni', active: false},
        ],

        radnaVisina: [
            {id: 0, value: 'verySmall', displayValue: '05-15', active: (this.props.rVisinaOne === 0) ? true : false},
            {id: 1, value: 'small', displayValue: '16-30', active: (this.props.rVisinaTwo === 1) ? true : false},
            {id: 2, value: 'medium', displayValue: '31-45', active: (this.props.rVisinaThree === 2) ? true : false}
        ],

        horizontalniDoseg: [
            {id: 0, value: 'verySmall', name: '0-10', active: (this.props.hDosegOne === 0) ? true : false},
            {id: 1, value: 'small', name: '11-20', active: (this.props.hDosegTwo === 1) ? true : false},
            {id: 2, value: 'medium', name: '21-30', active: (this.props.hDosegThree === 2) ? true : false}
        ],

        podkategorije: [],

        selectedKat: null,

        search: this.props.mainSearch !== '' ? this.props.mainSearch : '',

        searchDropdownState: false,
        searchDropdown: false,
        
    }


    componentDidMount() {



          axios.all([
            axios.get('https://webrentsite.firebaseio.com/prodajaPodizne.json'),
            axios.get('https://webrentsite.firebaseio.com/prodajaTeleskopskiVilicari.json'),
            axios.get('https://webrentsite.firebaseio.com/prodajaIskopi.json'),
            axios.get('https://webrentsite.firebaseio.com/prodajaCiscenje.json'),
            axios.get('https://webrentsite.firebaseio.com/prodajaVilicar.json')
          ])
          .then(response => {

            const podizne = []
            for(let key in response[0].data) {                                 
                podizne.push({                                         
                    ...response[0].data[key],
                    id: [key]
                }) 
            }


            const podizneStupneVertikalne = []
            for(let key in response[0].data.stupne.items) {                // stupne                      
                podizneStupneVertikalne.push({                                         
                    ...response[0].data.stupne.items[key],
                    id: [key]
                }) 
            }

            const podizneSkaraste = []                                     // skaraste
            for(let key in response[0].data.skaraste.items) {                                 
                podizneSkaraste.push({                                         
                    ...response[0].data.skaraste.items[key],
                    id: [key]
                }) 
            }

            const podizneAutokosara = []                                // autokosara
            for(let key in response[0].data.autokosara.items) {                                 
                podizneAutokosara.push({                                         
                    ...response[0].data.autokosara.items[key],
                    id: [key]
                }) 
            }

            const podizneZglobne = []                                   // zglobne
            for(let key in response[0].data.zglobne.items) {                                 
                podizneZglobne.push({                                         
                    ...response[0].data.zglobne.items[key],
                    id: [key]
                }) 
            }


            const podizneTeleskopske= []                                // podizneTeleskopske
            for(let key in response[0].data.teleskopskePlatforme.items) {                                 
                podizneTeleskopske.push({                                         
                    ...response[0].data.teleskopskePlatforme.items[key],
                    id: [key]
                }) 
            }


            const podiznePauk= []                                   // pauk
            for(let key in response[0].data.zglobneGusjenice.items) {                                 
                podiznePauk.push({                                         
                    ...response[0].data.zglobneGusjenice.items[key],
                    id: [key]
                }) 
            }

            const teleskopskiVilicari = []                         // teleskopski viliacari
            for(let key in response[1].data.items) {                                 
                teleskopskiVilicari.push({                                         
                    ...response[1].data.items[key],
                    id: [key]
                }) 
            }

            const damperi = []                                  // damper
            for(let key in response[2].data.damper.items) {                                 
                damperi.push({                                         
                    ...response[2].data.damper.items[key],
                    id: [key]
                }) 
            }

            const mikseri = []                                     // mikseri
            for(let key in response[2].data.mikseri.items) {                                 
                mikseri.push({                                         
                    ...response[2].data.mikseri.items[key],
                    id: [key]
                }) 
            }

            const utovarivaci = []                                 // utovarivaci
            for(let key in response[2].data.utovarivaci.items) {                                 
                utovarivaci.push({                                         
                    ...response[2].data.utovarivaci.items[key],
                    id: [key]
                }) 
            }

            const rovokopaci = []                                 // rovokopaci
            for(let key in response[2].data.rovokopaci.items) {                                 
                rovokopaci.push({                                         
                    ...response[2].data.rovokopaci.items[key],
                    id: [key]
                }) 
            }

            const cistilice = []                                   // cistilice
            for(let key in response[3].data.cistilice.items) {                                 
                cistilice.push({                                         
                    ...response[3].data.cistilice.items[key],
                    id: [key]
                }) 
            }

            const prodajaVilicari = []                          // prodajaVilicari
            for(let key in response[4].data.items) {                                 
                prodajaVilicari.push({                                         
                    ...response[4].data.items[key],
                    id: [key]
                }) 
            }

            
          let podkategorijeAll = []                                                  // iz this.state.kategorije sprema podkategorije sve u jednu varijablu

        


          for(let key in this.state.kategorije){
          
                for(let i in this.state.kategorije[key].podkategorije){
                    podkategorijeAll.push({
                        ...this.state.kategorije[key].podkategorije[i]
                    })
                }
            }

            
            if(response) {

                let all = Object.values(podizneSkaraste).concat(Object.values(podizneStupneVertikalne)).concat(Object.values(podizneAutokosara)).concat(Object.values(podizneZglobne)).concat(Object.values(podizneTeleskopske)).concat(Object.values(teleskopskiVilicari)).concat(Object.values(damperi)).concat(Object.values(mikseri)).concat(Object.values(utovarivaci)).concat(Object.values(cistilice)).concat(Object.values(prodajaVilicari)).concat(Object.values(podiznePauk)).concat(Object.values(rovokopaci))

                this.setState({
                    podizne: Object.values(podizne),
                    all: all,
                    podkategorije: Object.values(podkategorijeAll)
                });
            }    

          })
          .catch(error => {
            this.setState({error: true});
          });


            
          if(window.innerWidth < 500){

            this.setState({
                searchDropdownState: true
            })

        } 

        if(window.innerWidth > 500){

            this.setState({
                searchDropdown: true
            })

        } 


    }

    kategorijaHandler = (e, id) => {




        let allFilters = []
        
        if(
            (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].active === false) /*&&
            (
                (this.state.kategorije[0].active === false) &&
                (this.state.kategorije[1].active === false) &&
                (this.state.kategorije[2].active === false) &&
                (this.state.kategorije[3].active === false) &&
                (this.state.kategorije[4].active === false) 
            )*/
            ) {
            

                    const update = {
                        ...this.state.kategorije,
                       [((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id]: {
                        ...this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id],
                        active: true
                        }
                    }





                        this.setState({
                            kategorije: Object.values(update),
                            selectedKat: ((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id
                        })          

            
            

            let allPush = []
            let filtered = []
            if(
                (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].value === 'prodajaPodizne') ||
                (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].value === 'prodajaTeleskopskiVilicari') ||
                (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].value === 'prodajaVilicar') ||
                (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].value === 'prodajaIskopi') ||
                (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].value === 'prodajaCiscenje') 
            ){


               

                for(let key in this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].podkategorije) {

                        allPush.push(
                            (this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].podkategorije[key])
                            
                            );
                        
                        for(let e in this.state.kategorije) {

                            allPush.concat(this.state.kategorije[e].active ? this.state.kategorije[e].podkategorije[key] : null )

                        }
                        
                    
                }

                filtered = allPush.filter(function (el) {                                                      // filtrira one koji nisu null
                    return (el !== null) && (el !== undefined);
                    });     

                            
                allFilters.push(filtered)             // svi filteri

                

                let arr = []

                for(let key in allFilters){
                    for(let i in allFilters[key]){
                        arr.push({
                            ...allFilters[key][i],
                            valueID: parseInt(i)
                        })
                    }
                }


                this.setState({
                    podkategorije: Object.values(arr)
                })
                
            } 
            
        } else if ((this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id].active === true)) {
            const update = {
                ...this.state.kategorije,
               [((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id]: {
                ...this.state.kategorije[((this.props.katNUm === true) && (id === false)) ? this.props.katNUm : id],
                active: false
                
                }
            }

            let podkategorijeAll = []                                                  // iz this.state.kategorije sprema podkategorije sve u jednu varijablu
            for(let key in this.state.kategorije){
            
                  for(let i in this.state.kategorije[key].podkategorije){
                      podkategorijeAll.push({
                          ...this.state.kategorije[key].podkategorije[i]
                      })
                  }
              }


            this.setState({
                kategorije: Object.values(update),                 // kako bi se pravilno spremilo u state kao array a ne kao object
                podkategorije:  Object.values(podkategorijeAll),
                selectedKat: null
            })
    }


    
    }

    podkategorijaHandler = (e, id, valueID) => {

        
        
        if(!this.state.podkategorije[((valueID === 0) || (valueID === 1) || (valueID === 2) || (valueID === 3) || (valueID === 4) || (valueID === 5) || (valueID === 6) || (valueID === 7) || (valueID === 8) || (valueID === 9) || (valueID === 10) || (valueID === 11) || (valueID === 12) || (valueID === 13) || (valueID === 14) || (valueID === 15) ) ? valueID : id].active) {

            const update = {
                ...this.state.podkategorije,
               [((valueID === 0) || (valueID === 1) || (valueID === 2) || (valueID === 3) || (valueID === 4) || (valueID === 5) || (valueID === 6) || (valueID === 7) || (valueID === 8) || (valueID === 9) || (valueID === 10) || (valueID === 11) || (valueID === 12) || (valueID === 13) || (valueID === 14) || (valueID === 15) )  ? valueID : id]: {
                ...this.state.podkategorije[((valueID === 0) || (valueID === 1) || (valueID === 2) || (valueID === 3) || (valueID === 4) || (valueID === 5) || (valueID === 6) || (valueID === 7) || (valueID === 8) || (valueID === 9) || (valueID === 10) || (valueID === 11) || (valueID === 12) || (valueID === 13) || (valueID === 14) || (valueID === 15) )  ? valueID : id],
                active: true
                }
                }
            
            this.setState({
                podkategorije: Object.values(update)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.podkategorije,
               [((valueID === 0) || (valueID === 1) || (valueID === 2) || (valueID === 3) || (valueID === 4) || (valueID === 5) || (valueID === 6) || (valueID === 7) || (valueID === 8) || (valueID === 9) || (valueID === 10) || (valueID === 11) || (valueID === 12) || (valueID === 13) || (valueID === 14) || (valueID === 15) )  ? valueID : id]: {
                ...this.state.podkategorije[((valueID === 0) || (valueID === 1) || (valueID === 2) || (valueID === 3) || (valueID === 4) || (valueID === 5) || (valueID === 6) || (valueID === 7) || (valueID === 8) || (valueID === 9) || (valueID === 10) || (valueID === 11) || (valueID === 12) || (valueID === 13) || (valueID === 14) || (valueID === 15) )  ? valueID : id],
                active: false
                
                }
            }

            this.setState({
                podkategorije: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }


    }

    brandHandler = (e, id) => {

        
        if(
            (this.state.brand[id].active === false) /*&&
            (
                (this.state.brand[0].active === false) &&
                (this.state.brand[1].active === false) &&
                (this.state.brand[2].active === false) &&
                (this.state.brand[3].active === false) &&
                (this.state.brand[4].active === false) &&
                (this.state.brand[5].active === false) &&
                (this.state.brand[6].active === false) &&
                (this.state.brand[7].active === false)
            )*/
          ) {


              /*  update = {
                    ...this.state.brand,
                   [id]: {
                    ...this.state.brand[id],
                    active: (this.state.brand[1].id === id) ? true : false
                    }
                }*/

            const fetched = []
            for(let key in this.state.brand) {                         
                fetched.push({                               
                    ...this.state.brand[key],
                    active: (this.state.brand[key].id === id) ? true : false
                
                })
            } 
            
            
            this.setState({
                brand: Object.values(fetched)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.brand,
               [id]: {
                ...this.state.brand[id],
                active: false
                
                }
            }

            this.setState({
                brand: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }

    }



    pogonHandler = (e, id) => {

        
        if(!this.state.pogon[id].active) {

            const update = {
                ...this.state.pogon,
               [id]: {
                ...this.state.pogon[id],
                active: true
                }
                }
            
            this.setState({
                pogon: Object.values(update)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.pogon,
               [id]: {
                ...this.state.pogon[id],
                active: false
                
                }
            }

            this.setState({
                pogon: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }

    }

    radnaVisinaHandler  = (e, id) => {

        
        if(!this.state.radnaVisina[id].active) {

            const update = {
                ...this.state.radnaVisina,
               [id]: {
                ...this.state.radnaVisina[id],
                active: true
                }
                }
            
            this.setState({
                radnaVisina: Object.values(update)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.radnaVisina,
               [id]: {
                ...this.state.radnaVisina[id],
                active: false
                
                }
            }

            this.setState({
                radnaVisina: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }

    }


    searchHandler = (e) => {

            
        this.setState({
            search: e.target.value
            
        })


    }

    searchDropdown = () => {
        this.setState(prevState=>({
            searchDropdown: !prevState.searchDropdown
        }))
    }



    render() {




        let filtered = this.state.all

        let allFilters = []

        let filteredAll = []                                                                            // Pretraživač
        if((this.state.all.length !== 0) && (this.state.search !== '')){
            filteredAll = filtered.filter(
            (item) => {
                return  item.naziv.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.sifra.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.brand.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.pogon.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 

            }
        )}



        let filterBrand = [];       // brand
        let filteredBrand = []
        for (let i = 0; i < this.state.brand.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.brand[i].active === true) {                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.all) {   

                    filterBrand.push(
                    (this.state.all[key].brand === this.state.brand[i].value) ? this.state.all[key] : null);
                    filteredBrand = filterBrand.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });      
                   //                                                               

                }
                 
            }
        }

        let filteredKategorije = []

        let filterKategorije = [];      // kategorija
        for (let i = 0; i < this.state.kategorije.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.kategorije[i].active === true) {                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.all) {   

                    filterKategorije.push(
                    (this.state.all[key].kategorija === this.state.kategorije[i].value) ? this.state.all[key] : null);
                    filteredKategorije = filterKategorije.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });     
                    
                    //

                }

            }
        }

        /*
        
        && ((this.state.all[key].podkategorija === 'stupna') || (this.state.all[key].podkategorija === 'zglobna') || (this.state.all[key].podkategorija === 'teleskopska') || (this.state.all[key] .podkategorija === 'skarasta') || (this.state.all[key].podkategorija === 'teleskopskiVilicar') || (this.state.all[key].podkategorija === 'autokosare') || (this.state.all[key].podkategorija === 'pauk') || (this.state.all[key].podkategorija === 'nisko') || (this.state.all[key].podkategorija === 'visoko') || (this.state.all[key].podkategorija === 'cheono') || (this.state.all[key]  .podkategorija === 'rovokopac') || (this.state.all[key].podkategorija === 'utovarivac')  || (this.state.all[key].podkategorija === 'damper')  || (this.state.all[key].podkategorija === 'agregator')   || (this.state.all[key].podkategorija === 'kompresor') || (this.state.all[key].podkategorija === 'cistilica') || (this.state.all[key].podkategorija === 'perilica')) 
        
        */



        let filterPodKategorije = [];       // podkategorija
        let filteredPodKategorije = []
        for (let i = 0; i < this.state.podkategorije.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.podkategorije[i].active === true) {                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.all) {   

                    filterPodKategorije.push(
                    (this.state.all[key].podkategorija === this.state.podkategorije[i].value) ? this.state.all[key] : null);
                    filteredPodKategorije = filterPodKategorije.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });      
                   //                                                               

                }
                 
            }
        }

        let filterPogon = [];               // pogon
        let filteredPogon = []
        for (let i = 0; i < this.state.pogon.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
                                                                                                                // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
            if ((this.state.pogon[i].active === true)) {  

            for(let k = 0; k < this.state.kategorije.length; k++) {

                for (let key in this.state.all) {   
                   
                    
                        filterPogon.push(
                            (this.state.all[key].pogon === this.state.pogon[i].value) ? this.state.all[key] : null);
                            filteredPogon = filterPogon.filter(function (el) {                                                      // filtrira one koji nisu null
                            return el != null;
                            });   
                         //    
                }
                

            }
            

            }
                
        }

        filteredPogon = filteredPogon.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            t.id[0] === thing.id[0] && t.naziv === thing.naziv 
        ))
        )


        
        let filterVisina = [];      // radna visina
        let filteredVisina = [];
        for (let i = 0; i < this.state.radnaVisina.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.radnaVisina[i].active === true) {                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.all) {   

                    filterVisina.push(
                    (this.state.all[key].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[key] : null);
                    filteredVisina = filterVisina.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });      
                   //                                                               

                }
                   
            } 
        }



        let filterKatPodkat = []        // kategorija + podkategorija
        let filteredKatPodkat = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true)) {
                    for(let a in this.state.all) {
                        filterKatPodkat.push(
                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) ? this.state.all[a] : null);
                            filteredKatPodkat = filterKatPodkat.filter(function (el) {                                                      // filtrira one koji nisu null
                            return el != null;
                            });  
                            //
                    }
                    
                    
                } 
            }
        }

        
        let filterKatBrand = []        // kategorija + brand
        let filteredKatBrand = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.brand){
                if((this.state.kategorije[key].active === true) && (this.state.brand[e].active === true)) {
                    for(let a in this.state.all) {
                        filterKatBrand.push(
                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].brand === this.state.brand[e].value) ? this.state.all[a] : null);
                            filteredKatBrand = filterKatBrand.filter(function (el) {                                                      // filtrira one koji nisu null
                            return el != null;
                            });  
                            //
                    }
                    
                    
                } 
            }
        }

        let filterKatPodkatPo = []        // kategorija + podkategorija + pogon
        let filteredKatPodkatPo = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.pogon){
                    if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.pogon[p].active === true)) {
                        for(let a in this.state.all) {
                                filterKatPodkatPo.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].pogon === this.state.pogon[p].value) ? this.state.all[a] : null);
                                    filteredKatPodkatPo = filterKatPodkatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                        }
                        
                        
                        
                    } 
                }
                
            }
        }

        let filterKatPodkatPoRv = []        // kategorija + podkategorija + pogon + radna visina
        let filteredKatPodkatPoRv = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.pogon){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.pogon[p].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterKatPodkatPoRv.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].pogon === this.state.pogon[p].value)  && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredKatPodkatPoRv = filterKatPodkatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }
                        }
                    }
                }
            }
        }

        let filterKatPodkatRv = []        // kategorija + podkategorija + radna visina
        let filteredKatPodkatRv = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterKatPodkatRv.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredKatPodkatRv = filterKatPodkatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                
                
            }
        }


        let filterKatPo = []        // kategorija + pogon 
        let filteredKatPo = []
        for(let key in this.state.kategorije) {
                for(let p in this.state.pogon){
                    if((this.state.kategorije[key].active === true) && (this.state.pogon[p].active === true)) {
                        for(let a in this.state.all) {
                                filterKatPo.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].pogon === this.state.pogon[p].value) ? this.state.all[a] : null);
                                    filteredKatPo = filterKatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    
                }
                
            
        }

        
        let filterKatRv = []        // kategorija + radna visina
        let filteredKatRv = []
        for(let key in this.state.kategorije) {
                    for (let r in this.state.radnaVisina) {
                    if((this.state.kategorije[key].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterKatRv.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredKatRv = filterKatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                
                
            
        }

        let filterKatPoRv = []        // kategorija + pogon + radna visina
        let filteredKatPoRv = []
        for(let key in this.state.kategorije) {
                for(let p in this.state.pogon){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.kategorije[key].active === true) && (this.state.pogon[p].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterKatPoRv.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].pogon === this.state.pogon[p].value)  && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredKatPoRv = filterKatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                }
                
            
        }
        

        let filterPodkatPo = []        // podkategorija + pogon
        let filteredPodkatPo = []
            for(let e in this.state.podkategorije){
                for(let p in this.state.pogon){
                    if((this.state.podkategorije[e].active === true) && (this.state.pogon[p].active === true)) {
                        for(let a in this.state.all) {
                                filterPodkatPo.push(
                                    (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].pogon === this.state.pogon[p].value) ? this.state.all[a] : null);
                                    filteredPodkatPo = filterPodkatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    
                }
                
            }

            let filterPodkatBrand = []        // podkategorija + brand
            let filteredPodkatBrand = []
                for(let e in this.state.podkategorije){
                    for(let p in this.state.brand){
                        if((this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true)) {
                            for(let a in this.state.all) {
                                    filterPodkatBrand.push(
                                        (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value) ? this.state.all[a] : null);
                                        filteredPodkatBrand = filterPodkatBrand.filter(function (el) {                                                      // filtrira one koji nisu null
                                        return el != null;
                                        }); 
                                 
                                        //
                                }   
                                
                            }
                            
                            
                            
                        
                    }
                    
                }
        


        let filterPodkatRv = []        // podkategorija + radna visina
        let filteredPodkatRv = []
            for(let e in this.state.podkategorije){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.podkategorije[e].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterPodkatRv.push(
                                    (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredPodkatRv = filterPodkatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                
                
            }
        


        let filterPodkatPoRv = []        // podkategorija + pogon + radna visina
        let filteredPodkatPoRv = []
            for(let e in this.state.podkategorije){
                for(let p in this.state.pogon){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.podkategorije[e].active === true) && (this.state.pogon[p].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterPodkatPoRv.push(
                                    (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].pogon === this.state.pogon[p].value)  && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredPodkatPoRv = filterPodkatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                }
                
            }
        

        let filterPoRv = []        //  pogon + radna visina
        let filteredPoRv = []
                for(let p in this.state.pogon){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.pogon[p].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterPoRv.push(
                                    (this.state.all[a].pogon === this.state.pogon[p].value)  && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredPoRv = filterPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                                
                        }
                        
                        
                        
                    } 
                }

                let filterBrandRv = []        //  radna visina + brand
                let filteredBrandRv = []
                for(let p in this.state.brand){
                    for (let r in this.state.radnaVisina) {
                    if((this.state.brand[p].active === true) && (this.state.radnaVisina[r].active === true)) {
                        for(let a in this.state.all) {
                                filterBrandRv.push(
                                    (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].visinaLimit === this.state.radnaVisina[r].value) ? this.state.all[a] : null);
                                    filteredBrandRv = filterBrandRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }   
                            
                        }
                        
                        
                        
                    } 
                }

                let filterPoBrand = []        //  pogon + brand
                let filteredPoBrand = []
                        for(let p in this.state.pogon){
                            for (let r in this.state.brand) {
                            if((this.state.pogon[p].active === true) && (this.state.brand[r].active === true)) {
                                for(let a in this.state.all) {
                                        filterPoBrand.push(
                                            (this.state.all[a].pogon === this.state.pogon[p].value)  && (this.state.all[a].brand === this.state.brand[r].value) ? this.state.all[a] : null);
                                            filteredPoBrand = filterPoBrand.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                     
                                            //
                                    }   
                                        
                                }
                                
                                
                                
                            } 
                        }
                
                        
        let filterBrandKatPodkat= []        // brand + kategorija + podkategorija 
        let filteredBrandKatPodkat = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                    if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) ) {
                        for(let a in this.state.all) {
                                filterBrandKatPodkat.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value)   ? this.state.all[a] : null);
                                    filteredBrandKatPodkat = filterBrandKatPodkat.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }
                        }
                    
                }
            }
        }
            

        let filterBrandKatPodkatPo= []        // brand + kategorija + podkategorija + pogon
        let filteredBrandKatPodkatPo = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                    if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true)) {
                        for(let a in this.state.all) {
                                filterBrandKatPodkatPo.push(
                                    (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) ? this.state.all[a] : null);
                                    filteredBrandKatPodkatPo = filterBrandKatPodkatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                    return el != null;
                                    }); 
                             
                                    //
                            }
                        }
                    }
                }
            }
        }


        let filterBrandKatPodkatPoRv = []        // brand + kategorija + podkategorija + pogon + radna visina
        let filteredBrandKatPodkatPoRv = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                        for(let i in this.state.radnaVisina){
                            if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true)  && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandKatPodkatPoRv.push(
                                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandKatPodkatPoRv = filterBrandKatPodkatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            }
               
                        }
                }
            }
        }

        let filterBrandKatPodkatRv = []        // brand + kategorija + podkategorija + radna visina
        let filteredBrandKatPodkatRv = []
        for(let key in this.state.kategorije) {
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                        for(let i in this.state.radnaVisina){
                            if((this.state.kategorije[key].active === true) && (this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandKatPodkatRv.push(
                                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandKatPodkatRv = filterBrandKatPodkatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            }
               
                        
                }
            }
        }

        let filterBrandPodkatPoRv = []        // brand + podkategorija + pogon + radna visina
        let filteredBrandPodkatPoRv = []
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                        for(let i in this.state.radnaVisina){
                            if((this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true)  && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandPodkatPoRv.push(
                                            (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandPodkatPoRv = filterBrandPodkatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            
               
                        }
                }
            }
        }
        
        let filterBrandPodkatPo = []        // brand + podkategorija + pogon
        let filteredBrandPodkatPo = []
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                            if((this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true) ) {
                                for(let a in this.state.all) {
                                        filterBrandPodkatPo.push(
                                            (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) ? this.state.all[a] : null);
                                            filteredBrandPodkatPo = filterBrandPodkatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                
                            }
               
                        
                }
            }
        }


        let filterBrandPodkatRv = []        // brand + podkategorija + radna visina
        let filteredBrandPodkatRv = []
            for(let e in this.state.podkategorije){
                for(let p in this.state.brand){
                        for(let i in this.state.radnaVisina){
                            if((this.state.podkategorije[e].active === true) && (this.state.brand[p].active === true) && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandPodkatPoRv.push(
                                            (this.state.all[a].podkategorija === this.state.podkategorije[e].value) && (this.state.all[a].brand === this.state.brand[p].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandPodkatRv = filterBrandPodkatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            
               
                        
                }
            }
        }
        

        let filterBrandKatPoRv = []        // brand + kategorija + pogon + radna visina
        let filteredBrandKatPoRv = []
        for(let key in this.state.kategorije) {
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                        for(let i in this.state.radnaVisina){
                            if((this.state.kategorije[key].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true)  && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandKatPoRv.push(
                                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandKatPoRv = filterBrandKatPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                
                            }
               
                        }
                }
            }
        }


        
        let filterBrandKatPo = []        // brand + kategorija + pogon 
        let filteredBrandKatPo = []
        for(let key in this.state.kategorije) {
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                            if((this.state.kategorije[key].active === true) && (this.state.brand[p].active === true) && (this.state.pogon[r].active === true)  ) {
                                for(let a in this.state.all) {
                                        filterBrandKatPo.push(
                                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) ? this.state.all[a] : null);
                                            filteredBrandKatPo = filterBrandKatPo.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            
               
                        
                }
            }
        }
        

        
        let filterBrandKatRv = []        // brand + kategorija + radna visina
        let filteredBrandKatRv = []
        for(let key in this.state.kategorije) {
                for(let p in this.state.brand){
                        for(let i in this.state.radnaVisina){
                            if((this.state.kategorije[key].active === true) && (this.state.brand[p].active === true) && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandKatRv.push(
                                            (this.state.all[a].kategorija === this.state.kategorije[key].value) && (this.state.all[a].brand === this.state.brand[p].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandKatRv = filterBrandKatRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                }
                            
               
                        
                }
            }
        }

        let filterBrandPoRv = []        // brand + pogon + radna visina
        let filteredBrandPoRv = []
                for(let p in this.state.brand){
                    for (let r in this.state.pogon) {
                        for(let i in this.state.radnaVisina){
                            if((this.state.brand[p].active === true) && (this.state.pogon[r].active === true)  && (this.state.radnaVisina[i].active === true)) {
                                for(let a in this.state.all) {
                                        filterBrandPoRv.push(
                                            (this.state.all[a].brand === this.state.brand[p].value)  && (this.state.all[a].pogon === this.state.pogon[r].value) && (this.state.all[a].visinaLimit === this.state.radnaVisina[i].value) ? this.state.all[a] : null);
                                            filteredBrandPoRv = filterBrandPoRv.filter(function (el) {                                                      // filtrira one koji nisu null
                                            return el != null;
                                            }); 
                                    
                                            //
                                    }
                                
                            }
            
                        
                }
            }
        }


                                        // horizontalni doseg
    
                                        let filterVilicariHd = [];
                                        let filteredVilicariHd = []
                                
                                        for (let i = 0; i < this.state.horizontalniDoseg.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
                                            if (this.state.horizontalniDoseg[i].active === true) {   
                        
                                
                                                for (let key in this.state.all) { 
                                
                                                    filterVilicariHd.push(
                                                        (this.state.all[key].horizontalniDoseg === this.state.horizontalniDoseg[i].value) ? this.state.all[key] : null);
                                                        filteredVilicariHd = filterVilicariHd.filter(function (el) {                                                      // filtrira one koji nisu null
                                                        return el != null;
                                                        });  
                                
                                                }
                                                 
                                            }
                                        }
                                
                                                                           // radna visina + horizontalni doseg
                                        
                                        let filterVilicariRvHd = [];
                                        let filteredVilicariRvHd = []
                                
                                        for (let i = 0; i < this.state.radnaVisina.length; i++) {  
                                           for(let a = 0; a < this.state.horizontalniDoseg.length; a++){                                                  // prvo prolazi kroz kategorije i provjerava koje su true
                                            if ((this.state.radnaVisina[i].active === true) && (this.state.horizontalniDoseg[a].active === true)) {   
                                
                                                for (let v in this.state.all) { 
                                
                                                    filterVilicariRvHd.push(
                                                        (this.state.all[v].visinaLimit === this.state.radnaVisina[i].value) && (this.state.all[v].horizontalniDoseg === this.state.horizontalniDoseg[a].value) ? this.state.all[v] : null);
                                                        filteredVilicariRvHd = filterVilicariRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                                                        return el != null;
                                                        });  
                                
                                                }
                                                 
                                            }
                                        }
                                        
                                        }
                        
                                         // radna visina + horizontalni doseg + kategorija
                                        
                                         let filterVilicariKatRvHd = [];
                                         let filteredVilicariKatRvHd = []
                                                                               
                                         for (let i = 0; i < this.state.radnaVisina.length; i++) {  
                                            for(let a = 0; a < this.state.horizontalniDoseg.length; a++){               
                                                 for (let key = 0; key < this.state.kategorije.length; key++){                                  
                                                        if ((this.state.radnaVisina[i].active === true) && (this.state.horizontalniDoseg[a].active === true) && (this.state.kategorije[key].active === true)) {   
                                                                                        
                                                            for (let v in this.state.all) { 
                                                                                        
                                                                filterVilicariKatRvHd.push(
                                                                    (this.state.all[v].visinaLimit === this.state.radnaVisina[i].value) && (this.state.all[v].horizontalniDoseg === this.state.horizontalniDoseg[a].value) && (this.state.all[v].kategorija === this.state.kategorije[key].value) ? this.state.all[v] : null);
                                                                    filteredVilicariKatRvHd = filterVilicariKatRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                                                                    return el != null;
                                                                    });  
                                                                                        
                                                            }
                        
                                             }
                                            }
                                         }
                        
                                         }


                                         // horizontalni doseg + kategorija

                                         let filterVilicariKatHd = [];
                                         let filteredVilicariKatHd = []
                                                                               
                                         for (let i = 0; i < this.state.radnaVisina.length; i++) {  
                                            for(let a = 0; a < this.state.horizontalniDoseg.length; a++){               
                                                 for (let key = 0; key < this.state.kategorije.length; key++){                                  
                                                        if ((this.state.horizontalniDoseg[a].active === true) && (this.state.kategorije[key].active === true)) {   
                                                                                        
                                                            for (let v in this.state.all) { 
                                                                                        
                                                                filterVilicariKatHd.push(
                                                                    (this.state.all[v].horizontalniDoseg === this.state.horizontalniDoseg[a].value) && (this.state.all[v].kategorija === this.state.kategorije[key].value) ? this.state.all[v] : null);
                                                                    filteredVilicariKatHd = filterVilicariKatHd.filter(function (el) {                                                      // filtrira one koji nisu null
                                                                    return el != null;
                                                                    });  
                                                                                        
                                                            }
                        
                                             }
                                            }
                                         }
                        
                                         }
                                
                        
                                         if(                                                      // horizontalni doseg 
                                            (filteredVilicariHd.length > 0) &&
                                            (filteredVilicariRvHd.length === 0) &&
                                            (filteredVilicariKatRvHd.length === 0) &&
                                            (filteredVilicariKatHd.length === 0)
                                            ){
                                 
                                                allFilters.push(filteredVilicariHd)
                                 
                                           }  
                                        
                                        
                                        if(                                                      // radna visina + horizontalni doseg 
                                            (filteredVilicariRvHd.length > 0)  &&
                                            (filteredVilicariKatRvHd.length === 0)
                                            ){
                                 
                                                allFilters.push(filteredVilicariRvHd)
                                 
                                           }  

                                        if(                                                      // horizontalni doseg + kategorija
                                            (filteredVilicariKatHd.length > 0) &&
                                            (filteredVilicariKatRvHd.length === 0) 
                                            ){
                                           
                                                allFilters.push(filteredVilicariKatHd)
                                           
                                           } 

                                                                
                                        if(                                                      // radna visina + horizontalni doseg + kategorija
                                            (filteredVilicariKatRvHd.length > 0) 
                                            ){
                                           
                                                allFilters.push(filteredVilicariKatRvHd)
                                           
                                           } 
                                            
                                   

    /*    console.log('filtered')
        console.log(filteredKategorije)

        console.log('filteredPodKategorije')
        console.log(filteredPodKategorije)

        console.log('filteredPogon')
        console.log(filteredPogon)

        console.log('filteredVisina')
        console.log(filteredVisina)

        console.log('filteredKatPodkat')
        console.log(filteredKatPodkat)

        console.log('filteredKatPodkatPo')
        console.log(filteredKatPodkatPo)

        console.log('filteredKatPodkatPoRv')
        console.log(filteredKatPodkatPoRv)

        */


       if(                                                      // kategorija
           (filteredKategorije.length > 0) &&
           (filteredKatPodkat.length === 0) &&
           (filteredKatPodkatPo.length === 0) &&
           (filteredKatPodkatPoRv.length === 0) &&
           (filteredKatPo.length === 0) &&
           (filteredKatRv.length === 0) &&
           (filteredKatPoRv.length === 0) &&
           (filteredKatBrand.length === 0) &&
           (filteredBrandKatPodkat.length === 0)&&
           (filteredBrandKatPodkatPo.length === 0)&&
           (filteredBrandKatPodkatPoRv.length === 0) && 
           (filteredVilicariKatHd.length === 0) &&
           (filteredVilicariKatRvHd.length === 0) 
           ){

            allFilters.push(filteredKategorije)

          }  
       
       if (                                                     // podkategorija
            (filteredPodKategorije.length > 0)  &&
            (filteredKatPodkat.length === 0) &&
            (filteredKatPodkatPo.length === 0) &&
            (filteredPogon.length === 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredPodkatPo.length === 0) &&
            (filteredPodkatRv.length === 0) &&
            (filteredPodkatPoRv.length === 0) &&
            (filteredPodkatBrand.length === 0) &&
            (filteredBrandKatPodkat.length === 0) &&
            (filteredBrandKatPodkatPo.length === 0) &&
            (filteredBrandKatPodkatPoRv.length === 0)
        ) {
                allFilters.push(filteredPodKategorije)
        }  
       
       if (                                                     // pogon
            (filteredPogon.length > 0) &&
            (filteredKatPodkatPo.length === 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredKatPo.length === 0) &&
            (filteredPodkatPo.length === 0) && 
            (filteredPoRv.length === 0) &&
            (filteredKatPoRv.length === 0) &&
            (filteredPoBrand.length === 0)&&
            (filteredBrandKatPodkatPo.length === 0)
        ) {
                allFilters.push(filteredPogon)
        }  
       
       if (                                                     // radna visina
           (filterVisina.length > 0)  &&
           (filteredPodkatRv.length === 0) &&
           (filteredKatRv.length === 0) &&
           (filteredPoRv.length === 0) &&
           (filteredPodkatPoRv.length === 0) &&
           (filteredKatPodkatPoRv.length === 0) &&
           (filteredKatPoRv.length === 0) &&
           (filteredBrandRv.length === 0)&&
           (filteredBrandKatPodkatPoRv.length === 0)&& 
           (filteredVilicariKatHd.length === 0) &&
           (filteredVilicariKatRvHd.length === 0) 
        ) {
                allFilters.push(filterVisina)
        }  
       
  
        if (                                                     // kategorija + podkategorija
            (filteredKatPodkat.length > 0) &&
            (filteredKatPodkatPo.length === 0) &&
            (filteredKatPodkatPoRv.length === 0)&&
            (filteredBrandKatPodkat.length === 0)&&
            (filteredBrandKatPodkatPo.length === 0)&&
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatPodkat)
         }

         if (                                                     // kategorija + pogon
            (filteredKatPo.length > 0) &&
            (filteredKatPodkatPo.length === 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredKatPoRv.length === 0) &&
            (filteredBrandKatPodkatPoRv.length === 0)&&
            (filteredBrandKatPodkatPo.length === 0)  &&
            (filteredBrandKatPo.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatPo)
         }
        
         if (                                                     // kategorija + radna visina
            (filteredKatRv.length > 0) &&
            (filteredKatPoRv.length === 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredBrandKatPodkatPoRv.length === 0) &&
            (filteredBrandPodkatPoRv.length === 0)&&
            (filteredBrandKatPoRv.length === 0)&&
            (filteredBrandKatRv.length === 0)&&
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatRv)
         }

         if (                                                     // kategorija + pogon + radna visina
            (filteredKatPoRv.length > 0) &&
            (filteredKatPodkatPoRv.length === 0)&&
            (filteredBrandKatPodkatPoRv.length === 0)&&
            (filteredBrandPodkatPoRv.length === 0)&&
            (filteredBrandKatPoRv.length === 0)&&
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatPoRv)
         }
 
         if (                                                    // kategorija + podkategorija + pogon
             (filteredKatPodkatPo.length > 0) &&
             (filteredKatPodkatPoRv.length === 0)&&
             (filteredBrandKatPodkatPo.length === 0)&&
             (filteredBrandKatPodkatPoRv.length === 0)&&
             (filteredBrandPodkatPoRv.length === 0)&& 
             (filteredVilicariKatHd.length === 0) &&
             (filteredVilicariKatRvHd.length === 0) 
          ) {
                  allFilters.push(filteredKatPodkatPo)
          } 

         if (                                                    // kategorija + podkategorija + pogon + radna visina 
            (filteredKatPodkatPoRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatPodkatPoRv)
         }

         
         if (                                                    // kategorija + podkategorija + radna visina 
            (filteredKatPodkatRv.length > 0) &&
            (filteredKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredKatPodkatRv)
         }

         if (                                                    // podkategorija + pogon 
            (filteredPodkatPo.length > 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredPodkatPoRv.length === 0) &&
            (filteredKatPodkatPo.length === 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredBrandPodkatPo.length === 0)
         ) {
                 allFilters.push(filteredPodkatPo)
         }

         if (                                                    // podkategorija + radna visina 
            (filteredPodkatRv.length > 0) &&
            (filteredKatPodkatPoRv.length === 0) &&
            (filteredPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandPodkatRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredPodkatRv)
         }

         if (                                                    // podkategorija + pogon + radna visina 
            (filteredPodkatPoRv.length > 0) &&
            (filteredKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredPodkatPoRv)
         }

         if (                                                    // pogon + radna visina 
            (filteredPoRv.length > 0) &&
            (filteredKatPoRv.length === 0) &&
            (filteredPodkatPoRv.length === 0) &&
            (filteredKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredBrandPoRv.length === 0)&& 
            (filteredBrandKatPoRv.length === 0)&& 
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredPoRv)
         }

     

         
       if(                                                      // brand
        (filteredBrand.length > 0) && 
        (filteredKatBrand.length === 0)&& 
        (filteredPodkatBrand.length === 0) && 
        (filteredBrandRv.length === 0) && 
        (filteredPoBrand.length === 0) && 
        (filteredBrandKatPodkat.length === 0) && 
        (filteredBrandKatPodkatPoRv.length === 0) && 
        (filteredBrandKatPodkatRv.length === 0) && 
        (filteredBrandPodkatPoRv.length === 0) && 
        (filteredBrandPodkatPo.length === 0) && 
        (filteredBrandPodkatRv.length === 0) && 
        (filteredBrandKatPoRv.length === 0) && 
        (filteredBrandKatPo.length === 0) && 
        (filteredBrandKatRv.length === 0) && 
        (filteredBrandPoRv.length === 0) 
        ){

         allFilters.push(filteredBrand)

       }  

         if (                                                    // brand + kategorija 
            (filteredKatBrand.length > 0) && 
            (filteredBrandKatPodkatPo.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredBrandKatPoRv.length === 0)&& 
            (filteredBrandKatPo.length === 0)&& 
            (filteredBrandKatRv.length === 0)
         ) {
                 allFilters.push(filteredKatBrand)
         }

            
         if (                                                    // brand + podkategorija 
            (filteredPodkatBrand.length > 0) && 
            (filteredBrandKatPodkatPo.length === 0)&& 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredBrandPodkatPo.length === 0)&& 
            (filteredBrandPodkatRv.length === 0)
         ) {
                 allFilters.push(filteredPodkatBrand)
         }

         
         if (                                                    // brand + radna visina 
            (filteredBrandRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredBrandKatPoRv.length === 0)&& 
            (filteredBrandPodkatRv.length === 0)&& 
            (filteredBrandKatRv.length === 0)&& 
            (filteredBrandPoRv.length === 0)&& 
            (filteredVilicariKatRvHd.length === 0) 
         ) {
                 allFilters.push(filteredBrandRv)
         }

         if (                                                    // brand + pogon 
            (filteredPoBrand.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredBrandPodkatPoRv.length === 0)&& 
            (filteredBrandKatPoRv.length === 0)&& 
            (filteredBrandPodkatPo.length === 0)&& 
            (filteredBrandKatPo.length === 0)&& 
            (filteredBrandPoRv.length === 0)
         ) {
                 allFilters.push(filteredPoBrand)
         }


         if (                                                    // brand + kategorija + podkategorija 
            (filteredBrandKatPodkat.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredBrandKatPodkatRv.length === 0)&& 
            (filteredBrandKatPodkatPo.length === 0)
         ) {
                 allFilters.push(filteredBrandKatPodkat)
         }

         if (                                                    // brand + kategorija + podkategorija + pogon
            (filteredBrandKatPodkatPo.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatPodkatPo)
         }

         if (                                                    // brand + kategorija + podkategorija + pogon + radna visina
            (filteredBrandKatPodkatPoRv.length > 0) &&
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatPodkatPoRv)
         }

         if (                                                    // brand + kategorija + podkategorija + radna visina
            (filteredBrandKatPodkatRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0)&& 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatPodkatRv)
         }

         if (                                                    // brand + podkategorija + pogon + radna visina
            (filteredBrandPodkatPoRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandPodkatPoRv)
         }


         if (                                                    // brand + podkategorija + pogon 
            (filteredBrandPodkatPo.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredBrandKatPodkatPo.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandPodkatPo)
         }

         if (                                                    // brand + podkategorija + radna visina 
            (filteredBrandPodkatRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandPodkatRv)
         }

         if (                                                    // brand + kategorija + pogon + radna visina 
            (filteredBrandKatPoRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatPoRv)
         }

                 // filteredKatBrand, filteredPodkatBrand, filteredBrandRv, filteredPoBrand, filteredBrandKatPodkat, filteredBrandKatPodkatPo, filteredBrandKatPodkatPoRv, filteredBrandKatPodkatRv, filteredBrandPodkatPoRv, //filteredBrandPodkatPo, filteredBrandPodkatRv, filteredBrandKatPoRv, filteredBrandKatPo, filteredBrandKatRv, filteredBrandPoRv



         if (                                                    // brand + kategorija + pogon 
            (filteredBrandKatPo.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredBrandKatPodkatPo.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatPo)
         }
                                                                // brand + kategorija + podkategorija + radna visina
         
         if (                                                    
            (filterBrandKatPodkatRv.length > 0) && 
            (filterBrandKatPodkatPoRv.length === 0) 
            
         ) {
                 allFilters.push(filterBrandKatPodkatRv)
         }
          


         if (                                                    // brand + kategorija + radna visina 
            (filteredBrandKatRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredBrandKatPodkatRv.length === 0) && 
            (filteredBrandKatPoRv.length === 0) && 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandKatRv)
         }

         
         if (                                                    // brand + pogon + radna visina 
            (filteredBrandPoRv.length > 0) && 
            (filteredBrandKatPodkatPoRv.length === 0) && 
            (filteredBrandPodkatPoRv.length === 0) && 
            (filteredBrandKatPoRv.length === 0) && 
            (filteredVilicariKatHd.length === 0) &&
            (filteredVilicariKatRvHd.length === 0) 
            
         ) {
                 allFilters.push(filteredBrandPoRv)
         }



        //console.log('allFilters')
        //console.log(allFilters)

        //allFilters.push(filteredKategorije, filterPodKategorije, filterPogon, filterVisina, filteredKatPodkat, filteredKatPodkatPo)             // svi filteri

        let arr = []

        for(let key in allFilters){
            for(let i in allFilters[key]){
                arr.push({
                    ...allFilters[key][i]
                })
            }
        }


        let sum = arr.length ? arr : this.state.all
                                                                                                // filtrira sve prazne objekte a ostavlja pune
        let newSum  = sum.filter(value => Object.keys(value).length !== 0)      
                                                                                                // filtrira ako postoji vise istih 

        //console.log(newSum)                                                                                

        newSum = newSum.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            t.id[0] === thing.id[0] && t.naziv === thing.naziv
        ))
        )

        
       //console.log(newSum)
  
        let displayBoxes = filteredAll.length ? filteredAll : newSum

        let items = displayBoxes.map(p => {
            return(
                <div className="NajamItem"  key={p.id[0]}>
                    <img src={p.img} alt={p.img}/>
                    <h3>{p.naziv}</h3>
                    <div className="NajamItemSpec">

                        <table cellSpacing="1" cellPadding="1">
                            <tbody>
                                
                                <tr style={{display: p.brand ? 'table-row' : 'none'}}>
                                    <td>Brand</td>
                                    <td>{p.brand}</td>
                                </tr>
                                
                                <tr style={{display: 'none'}}>
                                    <td>Šifra</td>
                                    <td>{p.sifra}</td>
                                </tr>

                                <tr style={{display: p.pogon ? 'table-row' : 'none'}}>
                                    <td>Pogon</td>
                                    <td>{p.pogon}</td>
                                </tr>

                                <tr style={{display: p.radnaVisina ? 'table-row' : 'none'}}>
                                    <td>Radna visina</td>
                                    <td>{p.radnaVisina} m</td>
                                </tr>

                                <tr style={{display: p.radniDoseg ? 'table-row' : 'none'}}>
                                    <td>Radni doseg</td>
                                    <td>{p.radniDoseg} m</td>
                                </tr>

                                <tr style={{display: p.podizniKapacitet ? 'table-row' : 'none'}}>
                                    <td>Podizni kapacitet</td>
                                    <td>{p.podizniKapacitet} kg</td>
                                </tr>

                                <tr style={{display: 'none'}}>
                                    <td>Dimenzije</td>
                                    <td>{p.dimenzije}</td>
                                </tr>

                                <tr style={{display: p.platforma ? 'table-row' : 'none'}}>
                                    <td>Platforma</td>
                                    <td>{p.platforma}</td>
                                </tr>

                                <tr style={{display: p.tezina ? 'table-row' : 'none'}}>
                                    <td>Težina</td>
                                    <td>{p.tezina} kg</td>
                                </tr>


                            </tbody>
                        </table>

                        <a href={p.pdf} target="_blank" style={{display: p.pdf ? 'block' : 'none', textAlign: 'center'}}>Otvori PDF brošuru</a>

                    </div>
                </div>
            )
        })

        let brand = this.state.brand.map(k => {
            return(
                <div className="filterBtn" key={k.displayValue} onClick={(e) => this.brandHandler(e, k.id)} style={{background: k.active ? '#7cf455' : 'none'}}>
                    {k.displayValue}
                </div>
            )
        })

        let kategorije = this.state.kategorije.map(k => {
            return(
                <div className="filterBtn" key={k.id} onClick={(e) => this.kategorijaHandler(e, k.id)} style={{background: k.active ? '#7cf455' : 'none'}}>
                    {k.displayValue}
                </div>
            )
        })


        let podkategorije = this.state.podkategorije.map(k => {
            return(
                <div className="filterBtn" key={k.value} onClick={(e) => this.podkategorijaHandler(e, k.id, k.valueID)} style={{background: k.active ? '#7cf455' : 'none'}}>
                    {k.displayValue}
                </div>
            )
        })

        let pogon = this.state.pogon.map(k => {
            return(
                <div className="filterBtn" key={k.id} onClick={(e) => this.pogonHandler(e, k.id)} style={{background: k.active ? '#7cf455' : 'none'}}>
                    {k.displayValue}
                </div>
            )
        })

        let radnaVisina = this.state.radnaVisina.map(k => {
            return(
                <div className="filterBtn" key={k.id} onClick={(e) => this.radnaVisinaHandler(e, k.id)} style={{background: k.active ? '#7cf455' : 'none'}}>
                    {k.displayValue}
                </div>
            )
        })
    
        return (
            <div className="Najam">
     
                <div className="NajamSearch">

                <div style={{display: this.state.searchDropdownState ? 'flex' : 'none', justifyContent: 'space-around'}} onClick={this.searchDropdown}>Napredno pretraživanje 
                {this.state.searchDropdown ? <img style={{verticalAlign: 'middle'}} src={up} alt='up' /> : <img style={{verticalAlign: 'middle'}} src={down} alt='down'/>} </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                            <h3>Pretraži</h3>
                            <div className="NajamSearchFilterBox">
                                <input type="text" placeholder="Pretraži..." value={this.state.search} onChange={(e) => this.searchHandler(e)} name="search" />
                            </div>
                        </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                            <h3>Brand</h3>
                            <div className="NajamSearchFilterBox">
                                {brand}
                            </div>
                        </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                        <h3>Kategorija</h3>
                        <div className="NajamSearchFilterBox">
                            {kategorije}
                        </div>
                    </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                        <h3>Podkategorija</h3>
                        <div className="NajamSearchFilterBox">
                            {podkategorije}
                        </div>
                    </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                        <h3>Pogon</h3>
                        <div className="NajamSearchFilterBox">
                            {pogon}
                        </div>
                    </div>

                    <div className="NajamSearchDiv" style={{display: this.state.searchDropdown ? 'block' : 'none'}}>
                        <h3>Radna visina</h3>
                        <div className="NajamSearchFilterBox">
                            {radnaVisina}
                        </div>
                    </div>

                </div>

                <div className="NajamItems">
                    {items.length ? items : <div className="loader">Loading...</div>}
                </div>


            </div>
        );
    } 
    

    

}

export default Prodaja;
/*
let podizneItems = podizne[0].items.map(p => {
    return(
        <div className="NajamItem">
            <img src={p.img} />
            <h3>{p.naziv}</h3>
        </div>
    )
})
*/