import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'


import plane from '../../../assets/images/plane.svg'
import drop from '../../../assets/images/drop.svg'
import event from '../../../assets/images/event.svg'
import build from '../../../assets/images/build.svg'
import paint from '../../../assets/images/paint.svg'
import truck from '../../../assets/images/truck.svg'
import farm from '../../../assets/images/farm.svg'


class Industry extends Component {




    render() {


        let industry = this.props.industry.map(i => {

            return(
                <NavLink to={i.link} key={i.img} onClick={(e) => this.props.industryClickHandler(e, i.bgImage, i.img, i.title, i.text, i.link, i.id)}>
                    <div className="IndustryCircle"><img src={i.img} /></div>
                    <h5>{i.title}</h5>
                </NavLink>
            )

        })


        return (
            <div className="Industry">
                
                <h2>INDUSTRIJE</h2>
                
                <div className="IndustryWrap">
                    <div className="IndustryDiv">
                        {industry}
                    </div>
                </div>

            </div>
        );
    } 
    

    

}

export default Industry;
