import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

import podizne from '../../../assets/images/podizne.jpg'
import kosara from '../../../assets/images/kosara.jpg'
import vilicari from '../../../assets/images/vilicari.jpg'

import fiorentini from '../../../assets/images/fiorentini.png'

class Platforms extends Component {


    state={

        kategorije: [
            {id: 0, value: 'podizne', displayValue: 'Podizne platforme', active: (this.props.katNUm === 0) ? true : false, podkategorije:[
                {id: 0, value: 'stupna', displayValue: 'Stupne platforme', active: false},
                {id: 1, value: 'skarasta', displayValue: 'Škaraste platforme', active: false},
                {id: 2, value: 'zglobna', displayValue: 'Zglobne platforme', active: false},
                {id: 3, value: 'teleskopska', displayValue: 'Teleskopske platforme', active: false},
                {id: 4, value: 'autokosare', displayValue: 'Autokošare', active: false},
                {id: 5, value: 'pauk', displayValue: 'Pauk', active: false}
            ]},

            {id: 1, value: 'teleskopskiVilicari', displayValue: 'Teleskopski viličari', active: (this.props.katNUm === 1) ? true : false, podkategorije:[
                {id: 6, value: 'teleskopskiVilicar', displayValue: 'Teleskopski viličar', active: false},
            ]},

            {id: 2, value: 'vilicari', displayValue: 'Viličari', active: false, podkategorije:[
                {id: 7, value: 'nisko', displayValue: 'Nisko podizanje', active: false},
                {id: 8, value: 'visoko', displayValue: 'Visoko podizanje', active: false},
                {id: 9, value: 'cheoni', displayValue: 'Čeoni', active: false},    
            ]},

            {id: 3, value: 'strojeviZaIskop', displayValue: 'Strojevi za iskop', active: false, podkategorije:[
                {id: 10, value: 'rovokopac', displayValue: 'Rovokopači', active: false},
                {id: 11, value: 'utovarivac', displayValue: 'Utovarivači', active: false},
                {id: 12, value: 'damper', displayValue: 'Mini damper (istovarivač)', active: false},
                {id: 13, value: 'agregator', displayValue: 'Agregatori', active: false},
                {id: 14, value: 'kompresor', displayValue: 'Kompresori', active: false}
            ]},

            {id: 4, value: 'opremaZaCiscenje', displayValue: 'Oprema za čišćenje', active: false, podkategorije: [
                {id: 15, value: 'cistilica', displayValue: 'Čistilice', active: false},
                {id: 16, value: 'perilica', displayValue: 'Perilice', active: false}
            ]}
        ],

        allPodizne: [],
        allVilicari: [],

        allPodizneProdaja: [],
        allVilicariProdaja: [],

        move:[
            {id: 0, name: 'Osobe', active: false},
            {id: 1, name: 'Materijal', active: false}
        ],

        radnaVisina: [
            {id: 0, value: 'verySmall', name: '05-15', active: false},
            {id: 1, value: 'small', name: '16-30', active: false},
            {id: 2, value: 'medium', name: '31-45', active: false}
        ],

        horizontalniDoseg: [
            {id: 0, value: 'verySmall', name: '0-10', active: false},
            {id: 1, value: 'small', name: '11-20', active: false},
            {id: 2, value: 'medium', name: '21-30', active: false}
        ],

        tabs: [
            {id: 0, name: 'Najam strojeva', active: true},
            {id: 1, name: 'Prodaja novih strojeva', active: false},
            {id: 2, name: 'Rezervni dijelovi i ostalo', active: false}
        ],

        platforms: [
            {id: 0, name: 'Podizne platforme', active: false, img: podizne},
            {id: 1, name: 'Autokošare i građevinski strojevi', active: false, img: kosara},
            {id: 2, name: 'Viličari i teleskopski viličari', active: false, img: vilicari}
        ],

        cistilice: [
            {id: 4, name: 'Najam', active: false, img: podizne},
            {id: 4, name: 'Prodaja', active: false, img: vilicari}
        ],

        platformsNum: 0

    }

    componentDidMount() {


        axios.all([
          axios.get('https://webrentsite.firebaseio.com/podizne.json'),
          axios.get('https://webrentsite.firebaseio.com/teleskopskiVilicari.json'),
          axios.get('https://webrentsite.firebaseio.com/prodajaPodizne.json'),
          axios.get('https://webrentsite.firebaseio.com/prodajaTeleskopskiVilicari.json')
        ])
        .then(response => {

          const podizne = []
          for(let key in response[0].data) {                                 
              podizne.push({                                         
                  ...response[0].data[key],
                  id: [key]
              }) 
          }

                                                                                 // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NAJAM
          const podizneStupneVertikalne = []
          for(let key in response[0].data.stupna.vertikalne.items) {                                 
              podizneStupneVertikalne.push({                                         
                  ...response[0].data.stupna.vertikalne.items[key],
                  id: [key]
              }) 
          }

          const podizneStupneGurajuce = []
          for(let key in response[0].data.stupna.gurajuce.items) {                                 
              podizneStupneGurajuce.push({                                         
                  ...response[0].data.stupna.gurajuce.items[key],
                  id: [key]
              }) 
          }

          const podizneSkarasteDizel = []
          for(let key in response[0].data.skaraste.dizel.items) {                                 
              podizneSkarasteDizel.push({                                         
                  ...response[0].data.skaraste.dizel.items[key],
                  id: [key]
              }) 
          }

          const podizneSkarasteElektro = []
          for(let key in response[0].data.skaraste.elektro.items) {                                 
              podizneSkarasteElektro.push({                                         
                  ...response[0].data.skaraste.elektro.items[key],
                  id: [key]
              }) 
          }

          const podizneZglobneDizel = []
          for(let key in response[0].data.zglobne.dizel.items) {                                 
              podizneZglobneDizel.push({                                         
                  ...response[0].data.zglobne.dizel.items[key],
                  id: [key]
              }) 
          }

          const podizneZglobneElektro = []
          for(let key in response[0].data.zglobne.elektro.items) {                                 
              podizneZglobneElektro.push({                                         
                  ...response[0].data.zglobne.elektro.items[key],
                  id: [key]
              }) 
          }

          const podizneTeleskopskeDizel = []
          for(let key in response[0].data.teleskopske.dizel.items) {                                 
              podizneTeleskopskeDizel.push({                                         
                  ...response[0].data.teleskopske.dizel.items[key],
                  id: [key]
              }) 
          }

          const podizneTeleskopskiVilicariTeleskopski = []
          for(let key in response[1].data.teleskopski.items) {                                 
              podizneTeleskopskiVilicariTeleskopski.push({                                         
                  ...response[1].data.teleskopski.items[key],
                  id: [key]
              }) 
          }
                                                                            // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> PRODAJA
          const prodajaPodizneSkaraste = []
          for(let key in response[2].data.skaraste.items) {                                 
            prodajaPodizneSkaraste.push({                                         
                  ...response[2].data.skaraste.items[key],
                  id: [key]
              }) 
          }

          const prodajaPodizneStupne = []
          for(let key in response[2].data.stupne.items) {                                 
            prodajaPodizneStupne.push({                                         
                  ...response[2].data.stupne.items[key],
                  id: [key]
              }) 
          }

          const prodajaPodizneZglobne = []
          for(let key in response[2].data.zglobne.items) {                                 
            prodajaPodizneZglobne.push({                                         
                  ...response[2].data.zglobne.items[key],
                  id: [key]
              }) 
          }

          const prodajaVilicari = []
          for(let key in response[3].data.items) {                                 
            prodajaVilicari.push({                                         
                  ...response[3].data.items[key],
                  id: [key]
              }) 
          }

          
          if(response) {
                                    // NAJAM
              let allPodizne = Object.values(podizneStupneGurajuce).concat(Object.values(podizneStupneVertikalne)).concat(Object.values(podizneSkarasteDizel)).concat(Object.values(podizneSkarasteElektro)).concat(Object.values(podizneZglobneDizel)).concat(Object.values(podizneZglobneElektro)).concat(Object.values(podizneTeleskopskeDizel))

              let allVilicari = Object.values(podizneTeleskopskiVilicariTeleskopski)

                                    // PRODAJA
              let allPodizneProdaja = Object.values(prodajaPodizneSkaraste).concat(Object.values(prodajaPodizneStupne)).concat(Object.values(prodajaPodizneZglobne))

              let allVilicariProdaja = Object.values(prodajaVilicari)

              this.setState({
                  allPodizne: allPodizne,
                  allVilicari: allVilicari,

                  allPodizneProdaja: allPodizneProdaja,
                  allVilicariProdaja: allVilicariProdaja,
              });
          }    

        })
        .catch(error => {
          this.setState({error: true});
        });


  }

    tabHandler = (e, valueId) => {

        if(!this.state.tabs[valueId].active){

            const fetched = []
            for(let key in this.state.tabs) {                         
                fetched.push({                               
                    ...this.state.tabs[key],
                    active: (this.state.tabs[key].id === valueId) ? true : false
                
                })
            } 
        
            this.setState({
                tabs: fetched
            })    

        } 
    }

    moveHandler = (e, valueId) => {

        if(!this.state.move[valueId].active){

            const fetched = []
            for(let key in this.state.move) {                         
                fetched.push({                               
                    ...this.state.move[key],
                    active: (this.state.move[key].id === valueId) ? true : false
                
                })
            } 
        
            this.setState({
                move: fetched
            })    

        } else {

            const fetched = []
            for(let key in this.state.move) {                         
                fetched.push({                               
                    ...this.state.move[key],
                    active: false
                
                })
            } 
        
            this.setState({
                move: fetched
            })  

        }

    }


    radnaVisinaHandler = (e, id) => {

        if(!this.state.radnaVisina[id].active) {

            const update = {
                ...this.state.radnaVisina,
               [id]: {
                ...this.state.radnaVisina[id],
                active: true
                }
                }
            
            this.setState({
                radnaVisina: Object.values(update)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.radnaVisina,
               [id]: {
                ...this.state.radnaVisina[id],
                active: false
                
                }
            }

            this.setState({
                radnaVisina: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }

    }

    horizontalniDosegHandler = (e, id) => {

        if(!this.state.horizontalniDoseg[id].active) {

            const update = {
                ...this.state.horizontalniDoseg,
               [id]: {
                ...this.state.horizontalniDoseg[id],
                active: true
                }
                }
            
            this.setState({
                horizontalniDoseg: Object.values(update)                     // kako bi se pravilno spremilo u state kao array a ne kao object
            })

        } else {
            const update = {
                ...this.state.horizontalniDoseg,
               [id]: {
                ...this.state.horizontalniDoseg[id],
                active: false
                
                }
            }

            this.setState({
                horizontalniDoseg: Object.values(update)                 // kako bi se pravilno spremilo u state kao array a ne kao object
            })
    }

    }

    PlatformsItemsBtnHandler = (katId, rVisinaOne, rVisinaTwo, rVisinaThree, hDosegOne, hDosegTwo, hDosegThree, tabOne, tabTwo) => {

       // console.log(katId)
       // console.log(rVisinaOne)
       // console.log(rVisinaTwo)
       // console.log(rVisinaThree)
       // console.log(hDosegOne)
       // console.log(hDosegTwo)
       // console.log(hDosegThree)
       // console.log(tabOne)
       // console.log(tabTwo)

        this.props.selectedValues(katId, rVisinaOne, rVisinaTwo, rVisinaThree, hDosegOne, hDosegTwo, hDosegThree, tabOne, tabTwo)

    }



    render() {


        let tabs = this.state.tabs.map(t => {                                                               // tabovi
            return(
                <div className="PlatformsTopTab" onClick={(e) => this.tabHandler(e,t.id)} key={t.id} style={{background: t.active ? '#7cf455' : 'white'}}>
                        {t.name}
                </div>
            )
        })

        let move = this.state.move.map(m => {                                                               // pomicanje osoba ili materijala
            return(
                <div className="PlatformsMove" key={m.id} onClick={(e) => this.moveHandler(e,m.id)} style={{background: m.active ? '#dadada' : 'white'}}>
                    {m.name}
                </div>
            )
        })

        
        let radnaVisina = this.state.radnaVisina.map(r => {                                                 // radna visina
            return(
                <div className="PlatformsMove" key={r.id} onClick={(e) => this.radnaVisinaHandler(e, r.id)} style={{background: r.active ? '#dadada' : 'white'}}>
                    {
                        r.active ? <label style={{color: '#cc0000'}}>{r.name + ' X'}</label> : r.name
                    }
                </div>
            )
        })

        let horizontalniDoseg = this.state.horizontalniDoseg.map(h => {                                     // horizontalni doseg
            return(
                <div className="PlatformsMove" key={h.id} onClick={(e) => this.horizontalniDosegHandler(e, h.id)} style={{background: h.active ? '#dadada' : 'white'}}>
                    {
                        h.active ? <label style={{color: '#cc0000'}}>{h.name + ' X'}</label> : h.name
                    }
                </div>
            )
        })



            /*
                    --------------------------------------------- NAJAM ---------------------------------------------
            */

        let allPodizneFilters = []
        let allVilicariFilters = []



        let filterPodizneRv = [];       // radna visina
        let filteredPodizneRv = []
        
        let filterVilicariRv = [];
        let filteredVilicariRv = []

        for (let i = 0; i < this.state.radnaVisina.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.radnaVisina[i].active === true) {   
                                                                              // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.allPodizne) {   
                    

                    filterPodizneRv.push(
                    (this.state.allPodizne[key].visinaLimit === this.state.radnaVisina[i].value) ? this.state.allPodizne[key] : null);
                    filteredPodizneRv = filterPodizneRv.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });  

                }

                for (let key in this.state.allVilicari) { 

                    filterVilicariRv.push(
                        (this.state.allVilicari[key].visinaLimit === this.state.radnaVisina[i].value) ? this.state.allVilicari[key] : null);
                        filteredVilicariRv = filterVilicariRv.filter(function (el) {                                                      // filtrira one koji nisu null
                        return el != null;
                        });  

                }
                 
            }
        }


        let filterPodizneHd = [];       // horizontalni doseg
        let filteredPodizneHd = []
        
        let filterVilicariHd = [];
        let filteredVilicariHd = []

        for (let i = 0; i < this.state.horizontalniDoseg.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
            if (this.state.horizontalniDoseg[i].active === true) {   
                                                                              // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.allPodizne) {   
                    
                    filterPodizneHd.push(
                    (this.state.allPodizne[key].horizontalniDoseg === this.state.horizontalniDoseg[i].value) ? this.state.allPodizne[key] : null);
                    filteredPodizneHd = filterPodizneHd.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });  

                }

                for (let key in this.state.allVilicari) { 

                    filterVilicariHd.push(
                        (this.state.allVilicari[key].horizontalniDoseg === this.state.horizontalniDoseg[i].value) ? this.state.allVilicari[key] : null);
                        filteredVilicariHd = filterVilicariHd.filter(function (el) {                                                      // filtrira one koji nisu null
                        return el != null;
                        });  

                }
                 
            }
        }


        
        let filterPodizneRvHd = [];       // radna visina + horizontalni doseg
        let filteredPodizneRvHd = []
        
        let filterVilicariRvHd = [];
        let filteredVilicariRvHd = []

        for (let i = 0; i < this.state.radnaVisina.length; i++) {  
           for(let a = 0; a < this.state.horizontalniDoseg.length; a++){                                                  // prvo prolazi kroz kategorije i provjerava koje su true
            if ((this.state.radnaVisina[i].active === true) && (this.state.horizontalniDoseg[a].active === true)) {   
                                                                              // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                for (let key in this.state.allPodizne) {   
                    
                    filterPodizneRvHd.push(
                    (this.state.allPodizne[key].visinaLimit === this.state.radnaVisina[i].value) && (this.state.allPodizne[key].horizontalniDoseg === this.state.horizontalniDoseg[a].value) ? this.state.allPodizne[key] : null);
                    filteredPodizneRvHd = filterPodizneRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                    return el != null;
                    });  

                }

                for (let v in this.state.allVilicari) { 

                    filterVilicariRvHd.push(
                        (this.state.allVilicari[v].visinaLimit === this.state.radnaVisina[i].value) && (this.state.allVilicari[v].horizontalniDoseg === this.state.horizontalniDoseg[a].value) ? this.state.allVilicari[v] : null);
                        filteredVilicariRvHd = filterVilicariRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                        return el != null;
                        });  

                }
                 
            }
        }
        
        }

        

        

        if(                                                      // radna visina - podizne
            (filteredPodizneRv.length > 0) &&
            (filteredPodizneRvHd.length === 0)&&
            (filteredVilicariRvHd.length === 0)
            ){
 
                allPodizneFilters.push(filteredPodizneRv)
 
           }  

        if(                                                      // radna visina - vilicari
            (filteredVilicariRv.length > 0) &&
            (filteredPodizneRvHd.length === 0)&&
            (filteredVilicariRvHd.length === 0)
            ){
 
                allVilicariFilters.push(filteredVilicariRv)
 
           }  

        if(                                                      // horizontalni doseg - podizne
            (filteredPodizneHd.length > 0) &&
            (filteredPodizneRvHd.length === 0)&&
            (filteredVilicariRvHd.length === 0)
            ){
 
                allPodizneFilters.push(filteredPodizneHd)
 
           }  

        if(                                                      // horizontalni doseg - vilicari
            (filteredVilicariHd.length > 0) &&
            (filteredPodizneRvHd.length === 0)&&
            (filteredVilicariRvHd.length === 0)
            ){
 
                allVilicariFilters.push(filteredVilicariHd)
 
           }  
        
        if(                                                      // radna visina + horizontalni doseg - podizne
            (filteredPodizneRvHd.length > 0) 
            ){
 
                allPodizneFilters.push(filteredPodizneRvHd)
 
           }  
        
        if(                                                      // radna visina + horizontalni doseg - vilicari
            (filteredVilicariRvHd.length > 0) 
            ){
 
                allVilicariFilters.push(filteredVilicariRvHd)
 
           }  

           


        let arrPodizne = []                                                                     // radna visina i horizonzalni doseg za podizne

        for(let key in allPodizneFilters){
            for(let i in allPodizneFilters[key]){
                arrPodizne.push({
                    ...allPodizneFilters[key][i]
                })
            }
        }



        
        let sumPodizne = arrPodizne.length ? arrPodizne : this.state.allPodizne
                                                                                                // filtrira sve prazne objekte a ostavlja pune
        let newSumPodizne  = sumPodizne.filter(value => Object.keys(value).length !== 0)      
                                                                                                // filtrira ako postoji vise istih 
        newSumPodizne = newSumPodizne.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            t.id[0] === thing.id[0] && t.naziv === thing.naziv
        ))
        )


        let arrVilicari = []                                                                    // radna visina i horizonzalni doseg za vilicare

        for(let key in allVilicariFilters){
            for(let i in allVilicariFilters[key]){
                arrVilicari.push({
                    ...allVilicariFilters[key][i]
                })
            }
        }


        let sumVilicari = arrVilicari.length ? arrVilicari : this.state.allVilicari
                                                                                                // filtrira sve prazne objekte a ostavlja pune
        let newSumVilicari  = sumVilicari.filter(value => Object.keys(value).length !== 0)      
                                                                                                // filtrira ako postoji vise istih 
        newSumVilicari = newSumVilicari.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            t.id[0] === thing.id[0] && t.naziv === thing.naziv
        ))
        )


            /*
                    --------------------------------------------- PRODAJA ---------------------------------------------
            */

           let allPodizneProdajaFilters = []
           let allVilicariProdajaFilters = []
   
   
   
           let filterPodizneProdajaRv = [];       // radna visina
           let filteredPodizneProdajaRv = []
           
           let filterVilicariProdajaRv = [];
           let filteredVilicariProdajaRv = []
   
           for (let i = 0; i < this.state.radnaVisina.length; i++) {                                                        // prvo prolazi kroz kategorije i provjerava koje su true
               if (this.state.radnaVisina[i].active === true) {   
                                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                   for (let key in this.state.allPodizneProdaja) {   
                       
   
                       filterPodizneProdajaRv.push(
                       (this.state.allPodizneProdaja[key].visinaLimit === this.state.radnaVisina[i].value) ? this.state.allPodizneProdaja[key] : null);
                       filteredPodizneProdajaRv = filterPodizneProdajaRv.filter(function (el) {                                                      // filtrira one koji nisu null
                       return el != null;
                       });  
   
                   }
   
                   for (let key in this.state.allVilicari) { 
   
                       filterVilicariProdajaRv.push(
                           (this.state.allVilicariProdaja[key].visinaLimit === this.state.radnaVisina[i].value) ? this.state.allVilicariProdaja[key] : null);
                           filteredVilicariProdajaRv = filterVilicariProdajaRv.filter(function (el) {                                                      // filtrira one koji nisu null
                           return el != null;
                           });  
   
                   }
                    
               }
           }
   
   
           let filterPodizneProdajaHd = [];       // horizontalni doseg
           let filteredPodizneProdajaHd = []
           
           let filterVilicariProdajaHd = [];
           let filteredVilicariProdajaHd = []
   
           for (let i = 0; i < this.state.horizontalniDoseg.length; i++) {    
            for(let e = 0; e < this.state.kategorije.length; e++)  {                                                      // prvo prolazi kroz kategorije i provjerava koje su true
               if (this.state.horizontalniDoseg[i].active === true) {   
                                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                   for (let key in this.state.allPodizneProdaja) {   
                       
   
                    filterPodizneProdajaHd.push(
                       (this.state.allPodizneProdaja[key].horizontalniDoseg === this.state.horizontalniDoseg[i].value) && (this.state.allPodizneProdaja[key].kategorija === this.state.kategorije[e].value)  ? this.state.allPodizne[key] : null);
                       filteredPodizneProdajaHd = filterPodizneProdajaHd.filter(function (el) {                                                      // filtrira one koji nisu null
                       return el != null;
                       });  
   
                   }
   
                   for (let key in this.state.allVilicariProdaja) { 
   
                       filterVilicariProdajaHd.push(
                           (this.state.allVilicariProdaja[key].horizontalniDoseg === this.state.horizontalniDoseg[i].value) && (this.state.allPodizneProdaja[key].kategorija === this.state.kategorije[e].value) ? this.state.allVilicariProdaja[key] : null);
                           filteredVilicariProdajaHd = filterVilicariProdajaHd.filter(function (el) {                                                      // filtrira one koji nisu null
                           return el != null;
                           });  
   
                   }
                    
               }}
           }
   
   
           
           let filterPodizneProdajaRvHd = [];       // radna visina + horizontalni doseg + kategorija
           let filteredPodizneProdajaRvHd = []
           
           let filterVilicariProdajaRvHd = [];
           let filteredVilicariProdajaRvHd = []
   
           for (let i = 0; i < this.state.radnaVisina.length; i++) {  
              for(let e = 0; e < this.state.kategorije.length; e++){            
                  for(let a = 0; a < this.state.horizontalniDoseg.length; a++)  {                                   // prvo prolazi kroz kategorije i provjerava koje su true
               if ((this.state.radnaVisina[i].active === true) && (this.state.horizontalniDoseg[a].active === true)) {   
                                                                                 // onda prolazi kroz all i sukladno kategoriji ih dodaje u varijablu
                   for (let key in this.state.allPodizneProdaja) {   
                       
   
                       filterPodizneProdajaRvHd.push(
                       (this.state.allPodizneProdaja[key].visinaLimit === this.state.radnaVisina[i].value) && (this.state.allPodizneProdaja[key].horizontalniDoseg === this.state.horizontalniDoseg[a].value) && (this.state.allPodizneProdaja[key].kategorija === this.state.kategorije[e].value) ? this.state.allPodizneProdaja[key] : null);
                       filteredPodizneProdajaRvHd = filterPodizneProdajaRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                       return el != null;
                       });  
   
                   }
   
                   for (let key in this.state.allVilicariProdaja) { 
   
                       filterVilicariProdajaRvHd.push(
                           (this.state.allVilicariProdaja[key].visinaLimit === this.state.radnaVisina[i].value) && (this.state.allVilicariProdaja[key].horizontalniDoseg === this.state.horizontalniDoseg[a].value)  && (this.state.allPodizneProdaja[key].kategorija === this.state.kategorije[e].value)  ? this.state.allVilicariProdaja[key] : null);
                           filteredVilicariProdajaRvHd = filterVilicariProdajaRvHd.filter(function (el) {                                                      // filtrira one koji nisu null
                           return el != null;
                           });  
   
                   }
                }
            
           }}
           
           }
   
           if(                                                      // radna visina - podizne
               (filteredPodizneProdajaRv.length > 0) &&
               (filteredPodizneProdajaRvHd.length === 0)&&
               (filteredVilicariProdajaRvHd.length === 0)
               ){
    
                   allPodizneProdajaFilters.push(filteredPodizneProdajaRv)
    
              }  
   
           if(                                                      // radna visina - vilicari
               (filteredVilicariProdajaRv.length > 0) &&
               (filteredPodizneProdajaRvHd.length === 0)&&
               (filteredVilicariProdajaRvHd.length === 0)
               ){
    
                   allVilicariProdajaFilters.push(filteredVilicariProdajaRv)
    
              }  
   
           if(                                                      // horizontalni doseg - podizne
               (filteredPodizneProdajaHd.length > 0) &&
               (filteredPodizneProdajaRvHd.length === 0)&&
               (filteredVilicariProdajaRvHd.length === 0)
               ){
    
                   allPodizneProdajaFilters.push(filteredPodizneProdajaHd)
    
              }  
   
           if(                                                      // horizontalni doseg - vilicari
               (filteredVilicariProdajaHd.length > 0) &&
               (filteredPodizneProdajaRvHd.length === 0)&&
               (filteredVilicariProdajaRvHd.length === 0)
               ){
    
                   allVilicariProdajaFilters.push(filteredVilicariProdajaHd)
    
              }  
           
           if(                                                      // radna visina + horizontalni doseg - podizne
               (filteredPodizneProdajaRvHd.length > 0) 
               ){
    
                allPodizneProdajaFilters.push(filteredPodizneProdajaRvHd)
    
              }  
           
           if(                                                      // radna visina + horizontalni doseg - vilicari
               (filteredVilicariProdajaRvHd.length > 0) 
               ){
    
                   allVilicariProdajaFilters.push(filteredVilicariProdajaRvHd)
    
              }  
   
              
   
   
           let arrPodizneProdaja = []                                                                     // radna visina i horizonzalni doseg za podizne
   
           for(let key in allPodizneProdajaFilters){
               for(let i in allPodizneProdajaFilters[key]){
                arrPodizneProdaja.push({
                       ...allPodizneProdajaFilters[key][i]
                   })
               }
           }
   
   
           let sumPodizneProdaja = arrPodizneProdaja.length ? arrPodizneProdaja : this.state.allPodizneProdaja
                                                                                                   // filtrira sve prazne objekte a ostavlja pune
           let newSumPodizneProdaja  = sumPodizneProdaja.filter(value => Object.keys(value).length !== 0)      
                                                                                                   // filtrira ako postoji vise istih 
           newSumPodizneProdaja = newSumPodizneProdaja.filter((thing, index, self) =>
           index === self.findIndex((t) => (
               t.id[0] === thing.id[0] && t.naziv === thing.naziv
           ))
           )
   
   
           let arrVilicariProdaja = []                                                                    // radna visina i horizonzalni doseg za vilicare
   
           for(let key in allVilicariProdajaFilters){
               for(let i in allVilicariProdajaFilters[key]){
                   arrVilicariProdaja.push({
                       ...allVilicariProdajaFilters[key][i]
                   })
               }
           }
   
   
           let sumVilicariProdaja = arrVilicariProdaja.length ? arrVilicariProdaja : this.state.allVilicariProdaja
                                                                                                   // filtrira sve prazne objekte a ostavlja pune
           let newSumVilicariProdaja  = sumVilicariProdaja.filter(value => Object.keys(value).length !== 0)      
                                                                                                   // filtrira ako postoji vise istih 
           newSumVilicariProdaja = newSumVilicariProdaja.filter((thing, index, self) =>
           index === self.findIndex((t) => (
               t.id[0] === thing.id[0] && t.naziv === thing.naziv
           ))
           )


        let rVisinaOne = this.state.radnaVisina[0].active ? 0 : ''
        let rVisinaTwo = this.state.radnaVisina[1].active ? 1 : ''
        let rVisinaThree = this.state.radnaVisina[2].active ? 2 : ''

        let hDosegOne = this.state.horizontalniDoseg[0].active ? 0 : ''
        let hDosegTwo = this.state.horizontalniDoseg[1].active ? 1 : ''
        let hDosegThree= this.state.horizontalniDoseg[2].active ? 2 : ''

        let tabOne = this.state.tabs[0].active ? 0 : ''
        let tabTwo = this.state.tabs[1].active ? 1 : '' 
        
        let platforms = this.state.platforms.map(i => {                                                     // kategorije
            return(
                <div className="PlatformsItems" style={{opacity: this.state.move[(i.id===0) ? 0 : (i.id - 1)].active ? '1' : (this.state.move[0].active === true) || (this.state.move[1].active === true) ? '0.3' : '1'}} key={i.id}>


                    <h4>{i.name}</h4>
                    <img src={i.img} />
                    <Link to={this.state.tabs[0].active ? '/najam' : '/prodaja-novo'} style={{textDecoration: 'none', color: 'black'}}>
                        <div className="PlatformsItemsBtn" style={{cursor: this.state.move[(i.id===0) ? 0 : (i.id - 1)].active ? 'pointer' : (this.state.move[0].active === true) || (this.state.move[1].active === true) ? 'not-allowed' : 'pointer'}} onClick={() => this.PlatformsItemsBtnHandler (i.id, rVisinaOne, rVisinaTwo, rVisinaThree, hDosegOne, hDosegTwo, hDosegThree, tabOne, tabTwo) } >
                        
                            { 

                                this.state.tabs[0].active ?
                                (i.id === 0 ? 
                                'Prikaži '/* + ( (this.state.move[1].active === true) ? '0' : (newSumPodizne.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allPodizneFilters.length === 0) ? 0 : newSumPodizne.length) : this.state.allPodizne.length) )*/ +' modele' : 

                                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> gore je za podizne a dolje za vilicare

                                'Prikaži ' /*+ ( (this.state.move[0].active === true) ? '0' : (newSumVilicari.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allVilicariFilters.length === 0) ? 0 : newSumVilicari.length) : this.state.allVilicari.length) ) */+ ' modele') : 

                                    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GORE JE NAJAM A DOLJE PRODAJA <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

                                (i.id === 0 ? 
                                    'Prikaži ' /*+ ( (this.state.move[1].active === true) ? '0' : (newSumPodizneProdaja.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allPodizneProdajaFilters.length === 0) ? 0 : newSumPodizneProdaja.length) : this.state.allPodizneProdaja.length) ) */+ ' modele' : 
        
                                    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> gore je za podizne a dolje za vilicare
        
                                    'Prikaži ' /*+ ( (this.state.move[0].active === true) ? '0' : (newSumVilicariProdaja.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allVilicariProdajaFilters.length === 0) ? 0 : newSumVilicariProdaja.length) : this.state.allVilicariProdaja.length) )*/ + ' modele')
                            }
                        
                        </div>
                    </Link>
                </div>
            )
        })

        let cistilice = this.state.cistilice.map(i => {                                                     // kategorije
            return(
                <div className="PlatformsItems" key={i.name}>
                    <h4>{i.name}</h4>
                    {//<img src={i.img} />
                    }
                    <Link to={(i.name === 'Najam') ? '/najam' : '/prodaja-novo'} style={{textDecoration: 'none', color: 'black'}}>
                        <div className="PlatformsItemsBtn" onClick={() => this.PlatformsItemsBtnHandler (i.id, rVisinaOne, rVisinaTwo, rVisinaThree, hDosegOne, hDosegTwo, hDosegThree, tabOne, tabTwo) } >
                        
                            { 

                                this.state.tabs[0].active ?
                                ((i.name === 'Najam') ? 
                                'Prikaži '/* + ( (this.state.move[1].active === true) ? '0' : (newSumPodizne.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allPodizneFilters.length === 0) ? 0 : newSumPodizne.length) : this.state.allPodizne.length) )*/ +' modele' : 

                                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> gore je za podizne a dolje za vilicare

                                'Prikaži ' /*+ ( (this.state.move[0].active === true) ? '0' : (newSumVilicari.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allVilicariFilters.length === 0) ? 0 : newSumVilicari.length) : this.state.allVilicari.length) ) */+ ' modele') : 

                                    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GORE JE NAJAM A DOLJE PRODAJA <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

                                ((i.name === 'Najam') ? 
                                    'Prikaži ' /*+ ( (this.state.move[1].active === true) ? '0' : (newSumPodizneProdaja.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allPodizneProdajaFilters.length === 0) ? 0 : newSumPodizneProdaja.length) : this.state.allPodizneProdaja.length) ) */+ ' modele' : 
        
                                    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> gore je za podizne a dolje za vilicare
        
                                    'Prikaži ' /*+ ( (this.state.move[0].active === true) ? '0' : (newSumVilicariProdaja.length ? ( ((this.state.horizontalniDoseg[0].active === true) || (this.state.horizontalniDoseg[1].active === true) || (this.state.horizontalniDoseg[2].active === true) || (this.state.radnaVisina[0].active === true) || (this.state.radnaVisina[1].active === true) || (this.state.radnaVisina[2].active === true)) && (allVilicariProdajaFilters.length === 0) ? 0 : newSumVilicariProdaja.length) : this.state.allVilicariProdaja.length) )*/ + ' modele')
                            }
                        
                        </div>
                    </Link>
                </div>
            )
        })

        return (
            <div className="Platforms">

                <h2 style={{textTransform: "uppercase", textAlign: "center", marginBottom: "2%", marginTop: "3%"}}>Odaberite vrstu platforme</h2>
                
                
                <div className="PlatformsTop">

                    {tabs}

                </div>
            	
                <div className="PlatformsBottom">

                    <div className="PlatformsAttr">
                    <h4>Podižem:</h4>
                        <div className="PlatformsAttrDiv">
                            
                            {move}
                        </div>           
                    <h4>Radna visina:</h4>
                        <div className="PlatformsAttrDiv">
                            {radnaVisina}
                        </div>
                    <h4>Bočni doseg:</h4>
                        <div className="PlatformsAttrDiv">
                            {horizontalniDoseg}
                        </div>

                    </div>

                    <div className="PlatformsList">

                        {
                            this.state.tabs[0].active ? <h2 className="PlatformsListH4">Iznajmi me</h2> : (this.state.tabs[1].active === true) ? <h2 className="PlatformsListH4">Kupi me</h2> : null
                        }

                        <div className="PlatformsListWrap">

                            {
                                this.state.tabs[2].active ? <h2 className="PlatformsListLink">USKORO..</h2> //<Link className="PlatformsListLink" to='/kontakt'><h2>NA UPIT</h2></Link> 
                                : platforms
                            }

                        </div>

                    </div>

                </div>


                <h2 style={{textTransform: "uppercase", textAlign: "center", marginBottom: "2%", marginTop: "2%", fontSize: '26px'}}>Najam i prodaja čistilica i usisivača</h2>
            	
                <div className="PlatformsBottom" style={{padding:'8px'}}>

                    <div className="PlatformsList" style={{width: '80%'}}>

                        <div className="PlatformsListWrap">

                            <a href='http://fiorentinispa.com/it/homepage'><img src={fiorentini} /></a>
                            {cistilice}

                        </div>

                    </div>

                </div>

                <h2 style={{textTransform: "uppercase", textAlign: "center", marginBottom: "2%", marginTop: "2%", fontSize: '26px'}}>Prodaja polovnih strojeva</h2>
            	
                <div className="PlatformsBottom" style={{padding:'8px'}}>

                    <div className="PlatformsList" style={{width: '80%'}}>

                        <div className="PlatformsListWrap">

                        <a className="PlatformsItemsBtn" style={{textDecoration: 'none', color: 'black'}} href="https://www.njuskalo.hr/tvrtka/up-rent">NA UPIT</a>

                        </div>

                    </div>

                </div>



            </div>
        );
    } 
    

    

}

export default Platforms;
