import React, {Component} from 'react'
import Aux from '../hoc/Aux'
import axios from 'axios'
import {Route, Switch} from 'react-router-dom'

import ContactBox from './Main/ContactBox'

import Header from './Header/Header'
import TopHeader from './Header/TopHeader'
import Nav from './Header/Nav'
import Slider from './Header/Slider'
import Main from './Main/Main'
import Najam from './Main/NavPages/Najam'
import Prodaja from './Main/NavPages/Prodaja'
import Form from './Main/NavPages/Form'
import IndustryTemplate from './Main/Industry/IndustryTemplate'
import Obuka from './Main/NavPages/Obuka'
import Kontakt from './Main/NavPages/Kontakt'
import Onama from './Main/NavPages/Onama'
import Transporti from './Main/NavPages/Transporti'
import Footer from './Footer/Footer'

import '../assets/css/main.css'
import '../assets/css/header.css'

import plane from '../assets/images/plane.svg'
import drop from '../assets/images/drop.svg'
import event from '../assets/images/event.svg'
import build from '../assets/images/build.svg'
import paint from '../assets/images/paint.svg'
import truck from '../assets/images/truck.svg'
import farm from '../assets/images/farm.svg'
import homeImg from '../assets/images/homeImg.svg'

import BGplane from '../assets/images/plane.jpg'
import BGclean from '../assets/images/clean.jpg'
import BGevent from '../assets/images/event.jpg'
import BGbuild from '../assets/images/build.jpg'
import BGpaint from '../assets/images/paint.jpg'
import BGtruck from '../assets/images/elevate1.jpg'
import BGfarm from '../assets/images/farm.jpg'




class Layout extends Component {

    state={

        industry:[

            {id: 0, bgImage: BGplane, img: plane, title: 'Avioindustrija', link: '/avioindustrija', path: 'avioindustrija', text:'Avio industrija je jedno od područja koje zahtijeva korištenje strojeva za rad na visokom. U našoj ponudi možete naći razne zračne platforme koje imaju mogućnost nadogradnje posebne opreme, brojne dizalice (teleskopske i zglobne hidrauličke) te niz rampi. Naši proizvodi prigodni su izbor za rad na otvorenom i u zatvorenom prostoru. Ne zaboravite da UPRENT nudi i uslugu servisiranja tako da za sve probleme možete kontaktirati naše stručnjake u odjelu servisa. Spremni smo na zahtjevnost avio industrije u svim pogledima, uključujući visoke standarde sigurnosti.'},

            {id: 1, bgImage: BGclean, img: drop, title: 'Čišćenje', link: '/ciscenje', path: 'ciscenje', text:'Sektor čišćenja je jedan od sektora koji najviše beneficira od korištenja specijaliziranih strojeva za rad na visokom. Ovisno o vašem poslu i okruženju u kojem se on odvija, naši stručnjaci pronaći će proizvod iz široke ponude koju nudi UPRENT. Među našim proizvodima možete naći rampe, zračne platforme ili specijalizirane teleskopske viličare, a ukoliko niste sigurni koji bi vam proizvod najbolje odgovarao, uvijek možete kontaktirati naše zaposlenike koji će vam pružiti adekvatnu procjenu.'},

            {id: 2, bgImage: BGevent, img: event, title: 'Događanja', link: '/dogadanja', path: 'dogadanja', text:'Organizacija evenata zahtjeva različite radove koji su uvelike olakšani korištenjem strojeva za rad na visokom. U našoj ponudi možete pronaći velik broj proizvoda za vaše potrebe poput viličara, zračnih radnih platformi i dizalica. Na vašem raspolaganju stoje i razne opcije nadogradnje i specijalizacije strojeva u skladu s uvjetima s kojima ćete se susretati u organizaciji. U sektoru organizacije događanja postoje različiti radovi za koje su potrebni zračna radna platforma, teleskopski viličar ili viličar. Prilikom pripreme događanja, možda je potrebno omaknuti armirano-betonske konstrukcije ili pak izraditi pozornicu. Riwal nudi rješenja koja su specifična za ove različite vrste radova. Primjerice, naša zglobna hidraulična dizalica može doseći problematična mjesta, te je moguće postavljanje radne platforme s više prostora za dizanje tereta.'},

            {id: 3, bgImage: BGbuild, img: build, title: 'Graditeljstvo', link: '/graditeljstvo', path: 'graditeljstvo', text:'U sektoru industrijske proizvodnje često su potrebni strojevi poput viličara ili specijaliziranog teleskopskog viličara te zračne radne platforme. Naši proizvodi idealni su za rad u zatvorenom prostoru, ali i za rad na otvorenom. Naši stručnjaci spremni su vam ponuditi i nadogradnje ili specijalizacije strojeva kako bi odgovarali onome što tražite. U našem katalogu možete vidjeti sve proizvode koje vam nudimo, a ukoliko ih želite uživo procijeniti, možete ih naći i na našem skladištu uz prethodno dogovoren sastanak.'},

            {id: 4, bgImage: BGpaint, img: paint, title: 'Ličioci', link: '/licioci', path: 'licioci', text:'Ukoliko je grana vašeg poslovanja ličenje, sigurno ste se susreli s radom na visini. Svaki posao je specifičan, stoga vam UPRENT nudi strojeve kojima ćete učinkovito obaviti radove. Naši zaposlenici nude rješenja i za vrlo specifične zahtjeve naših klijenata, te naša ponuda stoga obuhvaća npr. uz teleskopske dizalice i zglobne hidraulične dizalice koje nude veći raspon okreta kako biste svoj posao mogli izvršiti i na najzahtjevnijim lokacijama. Vrlo je popularna i opcija nadogradnje strojeva dodatnom opremom. Naš tim nudi vam brojne mogućnosti prilagodbe strojeva za vaše potrebe poput guma koje ne ostavljaju tragove koje su vrlo tražene za rad u zatvorenom prostoru, a možete pronaći i priključke za radne platforme.'},

            {id: 5, bgImage: BGtruck, img: truck, title: 'Logistika i skladištenje', link: '/logistika-i-skladistenje', path: 'logistika-i-skladistenje', text:'Optimalna organizacija u području Logistike i skladištenja mogu olakšati funkcioniranje svake tvrtke. Naša ponuda uključuje proizvode za različite namjene, ovisno o prirodi vašeg posla. U katalogu možete pronaći strojeve posebno prilagođene za razne uvjete prijevoza ili podizanja tereta. Kontaktirajte naše zaposlenike ukoliko imate ikakvih dilema i pronaći ćemo pravo rješenje za vas.'},

            {id: 6, bgImage: BGfarm, img: farm, title: 'Poljoprivreda', link: '/poljoprivreda', path: 'poljoprivreda', text:'U poljoprivredi se mnogi poslovi mogu brže i jednostavnije odraditi uz pomoć strojeva za rad na visokom. Stoga su viličari i radne platforme jedni od najtraženijih proizvoda u našem asortimanu. Naši stručnjaci mogu vam ponuditi savjet kako biste odabrali optimalni proizvod za Vas, a UPRENT u svojoj ponudi nudi i mogućnost prilagodbe strojeva za vaše potrebe.'}

        ],

        items:[
            {name: '', link: '/', path: '', img: homeImg},
            {name: 'Najam', link: '/najam', path: 'najam'},
          //  {name: 'Prodaja rabljeno', link: '/prodaja-rabljeno', path: 'prodaja-rabljeno'},
            {name: 'Prodaja novo', link: '/prodaja-novo', path: 'prodaja-novo'},
            {name: 'Obuka', link: '/obuka', path: 'obuka'},
            {name: 'Transporti', link: '/transporti', path: 'transporti'},
            {name: 'O Up Rent-u', link: '/o-nama', path: 'o-nama'},
            {name: 'Kontakt', link: '/kontakt', path: 'kontakt'}
        ],

        bgImage: null,
        img: null,
        title: null,
        text: null,
        link: null,
        IndustryTemplateId: '',

        firebaseState:[],

        katId: '',

        rVisinaOne: '',
        rVisinaTwo: '',
        rVisinaThree: '',

        hDosegOne: '',
        hDosegTwo: '',
        hDosegThree: '',

        tabOne: '',
        tabTwo: '',

        mainSearch: ''

    }

    componentDidMount() {


        axios.get('https://webrentsite.firebaseio.com/industry.json')
          .then(response => {


            const fetched = []
            for(let key in response.data) {                         // iz beckenda dobivam object pa ga moram pretvorit u array
                fetched.push({                               // key je id tj odredeni member
                    ...response.data[key],
                    id: key
                
                })
            } 

            this.setState({firebaseState: response.data});


            
          })
          .catch(error => {
            this.setState({error: true});
          });
    }


    industryClickHandler = (e, bgImage, img, title, text, link, id) => {


        axios.put('https://webrentsite.firebaseio.com/industry.json', {

                bgImage: bgImage,
                img: img,
                title: title,
                text: text,
                link: link,
                IndustryTemplateId: id


                })
                .then(response => {


                    
                })
                .catch(error => {
            
                });  

        this.setState({
            bgImage: bgImage,
            img: img,
            title: title,
            text: text,
            link: link,
            IndustryTemplateId: id
        })

        console.log(id)


    }

    selectedValuesHandler = (katId, rVisinaOne, rVisinaTwo, rVisinaThree, hDosegOne, hDosegTwo, hDosegThree, tabOne, tabTwo) => {

        this.setState({
            katId: katId,

            rVisinaOne: rVisinaOne,
            rVisinaTwo: rVisinaTwo,
            rVisinaThree: rVisinaThree,

            hDosegOne: hDosegOne,
            hDosegTwo: hDosegTwo,
            hDosegThree: hDosegThree,

            tabOne: tabOne,
            tabTwo: tabTwo
        
        })


    }

    clickSearchHandler = (searchValue) => {
        this.setState({
            mainSearch: searchValue
        })
    }

    render() {  

                                                                                // linkovi za nav
        let Navpaths = []                                              
        for(let key = 1; key < this.state.items.length; key++) {                         
            Navpaths.push({      
                path: this.state.items[key].path
                
            })
        } 

        const NavSum = Object.keys(Navpaths).map(function(key) {                                     
            return Navpaths[key].path;
          });

        let allNavLinks = NavSum.join('|') 


        

                                                                            // linkovi za industriju
        let paths = []                                                      
        for(let key in this.state.industry) {                         
            paths.push({      
                path: this.state.industry[key].path
                
            })
        } 

        const sum = Object.keys(paths).map(function(key) {                                     
            return paths[key].path;
          });

        let allLinks = sum.join('|')                                    



                                                                           
        return (
            <Aux>                

                <Route path="/" exact >
                    <TopHeader/>
                    <Nav 
                        items={this.state.items}
                    />
                    <Slider 
                        clickSearchHandler={this.clickSearchHandler}
                    />
                    <Main 
                        industryClickHandler={this.industryClickHandler} 
                        industry={this.state.industry} 
                        selectedValues={this.selectedValuesHandler}
                    />
                </Route> 

                <Route path={"/("+ allNavLinks + "|" + allLinks +")"} exact >
                    <TopHeader/>
                    <Nav 
                        items={this.state.items}
                    />
                </Route>

                <Route path={"/najam"} exact>
                    <Najam 
                        katNUm={this.state.katId}
                        rVisinaOne={this.state.rVisinaOne}
                        rVisinaTwo={this.state.rVisinaTwo}
                        rVisinaThree={this.state.rVisinaThree}
                        hDosegOne={this.state.hDosegOne}
                        hDosegTwo={this.state.hDosegTwo}
                        hDosegThree={this.state.hDosegThree}
                        mainSearch={this.state.mainSearch}
                    />
                </Route> 

                <Route path={"/prodaja-novo"} exact > 
                    <Prodaja 
                        katNUm={this.state.katId}
                        rVisinaOne={this.state.rVisinaOne}
                        rVisinaTwo={this.state.rVisinaTwo}
                        rVisinaThree={this.state.rVisinaThree}
                        hDosegOne={this.state.hDosegOne}
                        hDosegTwo={this.state.hDosegTwo}
                        hDosegThree={this.state.hDosegThree}
                        mainSearch={this.state.mainSearch}
                    />
                </Route>

                <Route path={"/obuka"} exact component={Obuka} />

                <Route path={"/form"} exact component={Form} />

                <Route path={"/o-nama"} exact component={Onama} />

                <Route path={"/transporti"} exact component={Transporti} />

                <Route path={"/kontakt"} exact component={Kontakt} />

                <Route path={"/(" + allLinks + "|" + allNavLinks + "|" +")"} exact component={ContactBox} />              

                <Route path={"/(" + allLinks + ")"} exact > 
                    <IndustryTemplate 
                        IndustryTemplateTitle={this.state.title ? this.state.title : this.state.firebaseState.title}
                        IndustryTemplateText={this.state.text ? this.state.text : this.state.firebaseState.text}
                        IndustryTemplateImg={this.state.bgImage ? this.state.bgImage : this.state.firebaseState.bgImage}
                        IndustryTemplateId={this.state.IndustryTemplateId}
                    />
                </Route> 
                    
                <Route path={"/(" + allLinks + "|" + allNavLinks + "|" +")"} component={Footer} /> 
             
            </Aux>
        );
    } 
    

    

}

export default Layout; 
