import firebase from 'firebase/app'
import 'firebase/storage'

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCS3r3yaiJrwiJdq3pCi-3hNiC8TaY9S8c",
    authDomain: "webrentsite.firebaseapp.com",
    databaseURL: "https://webrentsite.firebaseio.com",
    projectId: "webrentsite",
    storageBucket: "webrentsite.appspot.com",
    messagingSenderId: "828935103767",
    appId: "1:828935103767:web:94e2faab0afc04bdac8352",
    measurementId: "G-PNRMR9XTNH"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage();

  export {
      storage, firebase as default
  }