import React, {Component} from 'react'
import {Link} from 'react-router-dom'


import sec from '../../../assets/images/sec.svg'
import rocket from '../../../assets/images/rocket.svg'
import platforma from '../../../assets/images/platforma.svg'
import time from '../../../assets/images/time.svg'
import idea from '../../../assets/images/idea.svg'

import hrv from '../../../assets/images/hrv.svg'


class Whyus extends Component {


    state={
        benefiti:[
            {icon: sec, title: 'Sigurnost', text: 'Sve platforme se prije isporuke klijentu pregledavaju. Naš servisni tim vodi računa da su svi strojevi konstantno u tip top stanju.'},
            {icon: rocket, title: 'Brzina', text: 'Isporuka i odvoz uvijek u dogovorenom vremenu. '},
            {icon: platforma, title: 'Dostupnost platformi', text: 'Kontinuirano povečavamo flotu strojeva kako bi vam uvijek mogli ponuditi pravu platformu za obaviti posao.'},
            {icon: time, title: 'Dostupnost', text: 'Naš servisni tim stoji vam na usluzi s 4 servisna vozila, 24 sata dnevno.'},
            {icon: idea, title: 'Stručnost', text: 'Naše bogato iskustvo omogućava vrhunske stručne savjete i inovativna rješenja prilagođena potrebi korisnika.'}
        ]
    }



    render() {


        let benefiti = this.state.benefiti.map(b => {
            return(
                <div className="Benefit" key={b.title}>
                    <img src={b.icon} />
                    <h4>{b.title}</h4>
                    <p>{b.text}</p>
                </div>
            )
        })


        return (
            <div className="Whyus">
                
                <div className="WhyusAbout">

                    <h1>O UP RENTU</h1> 

                    <p>
                    S bogatim iskustvom sakupljanim još od 2001. godine, Up Rent je izrastao u jednog od vodećih stručnjaka na području sigurnog i učinkovitog rada na visini te smo u zadnjih par godina postali jedno od najkvalitetnijih rješenja po pitanju skladišne logistike i održavanja te smo spremni ponuditi široki spektar viličara i industrijskih čistača. Trenutačno smo prisutni na hrvatskom, talijanskom, slovenskom, bosanskohercegovačkom i srpskom tržištu, a naši partneri su jedni od globalnih lidera u svojim branšama.
                    </p>

                    

                </div>

                <div  className="WhyusAbout">

                    <div>

                        <h1>UP RENT SJEDIŠTA</h1> 

                        <p>
                        Up rent sjedište se nalazi na adresi Radnička cesta 184, 10 000 Zagreb.
                        </p>

                        <Link to="/kontakt" ><div className="Btn">Kontakt</div></Link>

                    </div>

                    <div>
                        <img src={hrv} />
                    </div>

                </div>

                <div className="Benefiti">
                    {benefiti}
                </div>

            </div>
        );
    } 
    

    

}

export default Whyus;
/*<div className="Btn">Pročitaj više</div> */