import React, {Component} from 'react'







class WithStyles extends Component {




    render() {
        return (
            <div className="WithStyles">
                
                <a href={this.props.link}><img src={this.props.image} /></a>

            </div>
        );
    } 
    

    

}

export default WithStyles;
