import React, {Component} from 'react'

import {Link} from 'react-router-dom'


import phone from '../../assets/images/phone.svg'
import email from '../../assets/images/email.svg'


class ContactBox extends Component {




    render() {
        return (
            <div className="ContactBox">
                
                <Link to="/kontakt">
                    <div>
                        <img src={phone} alt='phone'/>
                    </div>
                </Link>

                <Link to="/kontakt">
                    <div>
                        <img src={email} alt='email'/>
                    </div>
                </Link>

            </div>
        );
    } 
    

    

}

export default ContactBox;
