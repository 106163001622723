import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'


//import sadieci from '../../assets/images/SAMSON.JPG'
import mix from '../../assets/images/mix.jpg'
import hc from '../../assets/images/hc.jpg'
import co from '../../assets/images/co.jpg'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import WithStyles from './WithStyles'


class Slider extends Component {


    state={
        slider:[
            {img: mix, title: 'Uz Vas od temelja do krova!', text: 'Bilo da vam treba bager, platforma, utovarivač, viličar ili čistilica, pratimo Vas kroz sve faze izgradnje i održavanja objekta.', btn: '', tab: 'Uz Vas od temelja do krova!', id: 0, active: false},

            {img: hc, title: 'Up Rent servisni tim', text: 'Kakvo god riješenje izabrali, naš servisni tim pruža Vam podršku 24-7 !', btn: '', tab: 'Up Rent servisni tim', id: 1, active: false},

            {img: co, title: 'Skinite našu mobilnu aplikaciju!', text: 'Uz cjelokupan pregled asortimana, možete ostvariti i dodatne popuste.', btn: 'Preuzmi', link: 'https://play.google.com/store/apps/details?id=hr.uprent', tab: 'Skinite našu mobilnu aplikaciju!', id: 2, active: false}
        ],

        search: '',
        allNajam: [],
        allProdaja: []
    }


    
    componentDidMount() {


        axios.all([
          axios.get('https://webrentsite.firebaseio.com/prodajaPodizne.json'), // PRODAJA
          axios.get('https://webrentsite.firebaseio.com/prodajaTeleskopskiVilicari.json'),
          axios.get('https://webrentsite.firebaseio.com/prodajaIskopi.json'),
          axios.get('https://webrentsite.firebaseio.com/prodajaCiscenje.json'),
          axios.get('https://webrentsite.firebaseio.com/prodajaVilicar.json'),
          axios.get('https://webrentsite.firebaseio.com/podizne.json'),  // NAJAM
          axios.get('https://webrentsite.firebaseio.com/teleskopskiVilicari.json'),
          axios.get('https://webrentsite.firebaseio.com/iskopi.json'),
          axios.get('https://webrentsite.firebaseio.com/ciscenje.json'),
          axios.get('https://webrentsite.firebaseio.com/vilicari.json')
        ])
        .then(response => {

          const podizne = []
          for(let key in response[0].data) {                                 
              podizne.push({                                         
                  ...response[0].data[key],
                  id: [key]
              }) 
          }


          const podizneStupneVertikalne = []
          for(let key in response[0].data.stupne.items) {                                 
              podizneStupneVertikalne.push({                                         
                  ...response[0].data.stupne.items[key],
                  id: [key]
              }) 
          }


          const podizneSkaraste = []
          for(let key in response[0].data.skaraste.items) {                                 
              podizneSkaraste.push({                                         
                  ...response[0].data.skaraste.items[key],
                  id: [key]
              }) 
          }

          

          const podizneAutokosara = []
          for(let key in response[0].data.autokosara.items) {                                 
              podizneAutokosara.push({                                         
                  ...response[0].data.autokosara.items[key],
                  id: [key]
              }) 
          }

          

          const podizneZglobne = []
          for(let key in response[0].data.zglobne.items) {                                 
              podizneZglobne.push({                                         
                  ...response[0].data.zglobne.items[key],
                  id: [key]
              }) 
          }

          

          const podizneTeleskopske= []
          for(let key in response[0].data.teleskopskePlatforme.items) {                                 
              podizneTeleskopske.push({                                         
                  ...response[0].data.teleskopskePlatforme.items[key],
                  id: [key]
              }) 
          }

          const teleskopskiVilicari = []
          for(let key in response[1].data.items) {                                 
              teleskopskiVilicari.push({                                         
                  ...response[1].data.items[key],
                  id: [key]
              }) 
          }

          const damperi = []
          for(let key in response[2].data.damper.items) {                                 
              damperi.push({                                         
                  ...response[2].data.damper.items[key],
                  id: [key]
              }) 
          }

          const mikseri = []
          for(let key in response[2].data.mikseri.items) {                                 
              mikseri.push({                                         
                  ...response[2].data.mikseri.items[key],
                  id: [key]
              }) 
          }

          const utovarivaci = []
          for(let key in response[2].data.utovarivaci.items) {                                 
              utovarivaci.push({                                         
                  ...response[2].data.utovarivaci.items[key],
                  id: [key]
              }) 
          }

          const cistilice = []
          for(let key in response[3].data.cistilice.items) {                                 
              cistilice.push({                                         
                  ...response[3].data.cistilice.items[key],
                  id: [key]
              }) 
          }

          const prodajaVilicari = []
          for(let key in response[4].data.items) {                                 
              prodajaVilicari.push({                                         
                  ...response[4].data.items[key],
                  id: [key]
              }) 
          }

                        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>          NAJAM


           const podizneStupneVertikalneN = []
            for(let key in response[5].data.stupna.vertikalne.items) {                                 
                podizneStupneVertikalne.push({                                         
                    ...response[5].data.stupna.vertikalne.items[key],
                    id: [key]
                }) 
            }

            const podizneStupneGurajuce = []
            for(let key in response[5].data.stupna.gurajuce.items) {                                 
                podizneStupneGurajuce.push({                                         
                    ...response[5].data.stupna.gurajuce.items[key],
                    id: [key]
                }) 
            }

            const podizneSkarasteDizel = []
            for(let key in response[5].data.skaraste.dizel.items) {                                 
                podizneSkarasteDizel.push({                                         
                    ...response[5].data.skaraste.dizel.items[key],
                    id: [key]
                }) 
            }

            const podizneSkarasteElektro = []
            for(let key in response[5].data.skaraste.elektro.items) {                                 
                podizneSkarasteElektro.push({                                         
                    ...response[5].data.skaraste.elektro.items[key],
                    id: [key]
                }) 
            }

            const podizneZglobneDizel = []
            for(let key in response[5].data.zglobne.dizel.items) {                                 
                podizneZglobneDizel.push({                                         
                    ...response[5].data.zglobne.dizel.items[key],
                    id: [key]
                }) 
            }

            const podizneZglobneElektro = []
            for(let key in response[5].data.zglobne.elektro.items) {                                 
                podizneZglobneElektro.push({                                         
                    ...response[5].data.zglobne.elektro.items[key],
                    id: [key]
                }) 
            }

            const podizneTeleskopskeDizel = []
            for(let key in response[5].data.teleskopske.dizel.items) {                                 
                podizneTeleskopskeDizel.push({                                         
                    ...response[5].data.teleskopske.dizel.items[key],
                    id: [key]
                }) 
            }

            const podizneTeleskopskiVilicariTeleskopski = []
            for(let key in response[6].data.teleskopski.items) {                                 
                podizneTeleskopskiVilicariTeleskopski.push({                                         
                    ...response[6].data.teleskopski.items[key],
                    id: [key]
                }) 
            }


          
          if(response) {

            
              let allNajam = Object.values(podizneStupneVertikalneN).concat(Object.values(podizneStupneGurajuce)).concat(Object.values(podizneSkarasteDizel)).concat(Object.values(podizneSkarasteElektro)).concat(Object.values(podizneZglobneDizel)).concat(Object.values(podizneZglobneElektro)).concat(Object.values(podizneTeleskopskeDizel)).concat(Object.values(podizneTeleskopskiVilicariTeleskopski))

              

              let allProdaja = Object.values(podizneSkaraste).concat(Object.values(podizneStupneVertikalne)).concat(Object.values(podizneAutokosara)).concat(Object.values(podizneZglobne)).concat(Object.values(podizneTeleskopske)).concat(Object.values(teleskopskiVilicari)).concat(Object.values(damperi)).concat(Object.values(mikseri)).concat(Object.values(utovarivaci)).concat(Object.values(cistilice)).concat(Object.values(prodajaVilicari))


              this.setState({
                  allNajam: allNajam,
                  allProdaja: allProdaja
              });
          }    

        })
        .catch(error => {
          this.setState({error: true});
        });


          



  }


    tabHandler = (e, id) => {

        const slider = []
        for (let key in this.state.slider){
            slider.push({
                ...this.state.slider[key],
                active:  (this.state.slider[key].id === id) ? true : false
            })
        }


        this.setState({
            slider: slider
           })


    
    }

    searchHandler = (e) => {

            
        this.setState({
            search: e.target.value
            
        })


    }

    clickSearchHandler = (searchValue) => {

        this.props.clickSearchHandler(searchValue)

    }


    render() {

        let title = null
        let img = null
        let text = null
        let btn = null
        let link = null

        if(this.state.slider[0].active) {

            title = this.state.slider[0].title
            img = this.state.slider[0].img
            text = this.state.slider[0].text
            btn = this.state.slider[0].btn
            link = this.state.slider[0].link

        } else if(this.state.slider[1].active) {

            title = this.state.slider[1].title
            img = this.state.slider[1].img
            text = this.state.slider[1].text
            btn = this.state.slider[1].btn
            link = this.state.slider[1].link

        } else if(this.state.slider[2].active) {

            title = this.state.slider[2].title
            img = this.state.slider[2].img
            text = this.state.slider[2].text
            btn = this.state.slider[2].btn
            link = this.state.slider[2].link

        }





        let slider = this.state.slider.map(i => {
            return(
                    <div key={i.id} style={{backgroundImage:"url(" + img + ")"}} className="Slide">
                        <div className="SlideBox">
                            <h2>
                                {   
                                   title 
                                }
                            </h2>
                            <p>{text}</p>
                            <button style={{display: link ? 'block' : 'none'}}><a href={link} style={{textDecoration: 'none', color: 'black'}}>{btn}</a></button> 
                        </div>
                    </div>
            )
        })

        let tabs = this.state.slider.map(i => {
            return(
                <div key={i.id} className="SliderTabs" style={{background: i.active ? '#fff200' : '#eef3dd'}}>
                    <p>{i.tab}</p>
                </div>
            )
        })


        let filteredNajam = this.state.allNajam
        let filteredAllNajam = []        
                                                                                                    // Pretraživač najam
        if((this.state.allNajam.length !== 0) && (this.state.search !== '')){
            filteredAllNajam = filteredNajam.filter(
            (item) => {
                return  item.naziv.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.sifra.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.pogon.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 

            }
       )}


       let filteredProdaja = this.state.allProdaja
        let filteredAllProdaja = []        
                                                                                                    // Pretraživač prodaja
        if((this.state.allProdaja.length !== 0) && (this.state.search !== '')){
            filteredAllProdaja = filteredProdaja.filter(
            (item) => {
                return  item.naziv.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.sifra.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                        item.pogon.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 

            }
       )}


        return (
            <div className="Slider">
                
                <div className="SliderWrap">
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay
                    autoPlaySpeed={4000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={true}
                    responsive={{
                        desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1,
                        partialVisibilityGutter: 40
                        },
                        mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                        }
                    }}
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    >
                    <WithStyles
                        description={this.state.slider[0].text}
                        headline={this.state.slider[0].title}
                        image={this.state.slider[0].img}
                        btn={this.state.slider[0].btn}
                        link={this.state.slider[0].link}

                    />
                    <WithStyles
                        description={this.state.slider[1].text}
                        headline={this.state.slider[1].title}
                        image={this.state.slider[1].img}
                        btn={this.state.slider[1].btn}
                        link={this.state.slider[1].link}
                    />
                    <WithStyles
                        description={this.state.slider[2].text}
                        headline={this.state.slider[2].title}
                        image={this.state.slider[2].img}
                        btn={this.state.slider[2].btn}
                        link={this.state.slider[2].link}
                    />
                </Carousel>
                </div>

                <div className="SliderBottom">

                    <div className="SliderInput">
                        <input style={{marginTop: this.state.search ? '2%' : 'none'}} type="text" placeholder="Pretraži" onChange={(e) => this.searchHandler(e)} />
                        <div style={{display: (this.state.search !== '') ? 'block' : 'none'}} className="SliderInputDiv">
                        <Link to="/najam"><p onClick={() => this.clickSearchHandler(this.state.search)}>{'Najam platformi: ' + (filteredAllNajam.length ? filteredAllNajam.length : 0)}</p></Link>
                        <Link to="/prodaja-novo"><p onClick={() => this.clickSearchHandler(this.state.search)}>{'Prodaja novih platformi: ' + (filteredAllProdaja.length ? filteredAllProdaja.length : 0)}</p></Link>
                        </div>
                    </div>

                   

                </div>

            </div>
        );
    } 
    

    

}

export default Slider;

/*
 <div className="SliderTabsWrap">
                        {
                            tabs
                        }
                    </div>
*/