import React, {Component} from 'react'
import axios from 'axios'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import WithStyles from './WithStyles'

import Platforms from './Platforms/Platforms'
import {storage} from '../../firebase/firebase'

import Blocks from './Blocks/Blocks'
import Industry from './Industry/Industry'
import Brands from './Brands/Brands'
import Whyus from './Whyus/Whyus'

import '../../assets/css/main.scss'


class Main extends Component {


    state={
        image: null, 							// slika koja se ucita kada se odabere 
		url: '', 								// url ucitane slike nakon sta se uploada na firebase
		percentage: null, 						// postotak koji se prikaze prilikom postavljanja slike - da korisnik zna koliko treba 
        naziv: '',
        sifra: '',
        pogon: '',
        radnaVisina: null,
        radniDoseg: null,
        podizniKapacitet: null,
        dimenzije: '',
        platforma: '',
        tezina: null
    }

    inputChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })


    }

    fileHandler = (e) => {

        if(e.target.files[0]) {											// postavljanje odabrane slike kao image state
            const image = e.target.files[0];
            this.setState(() => ({image}));
		}

    }

    uploadHandler = () => {

		
		
		const {image} = this.state;										//uploadanje slike na firebase
		const uploadTask = storage.ref(`${image.name}`).put(image);
		uploadTask.on('state_changed', 
		(snapshot) => {
				let percentage = Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100)
				this.setState({percentage: percentage})
		}, 
		(error) => { 													// error function
			console.log(error);
		}, 
		() => {
																		// complete funtion
		storage.ref('').child(image.name).getDownloadURL().then(url => {
			this.setState({url});
		})
		
		});  
    }

    sendHandler = () => {

        if(
            (this.state.naziv !== '') &&
            (this.state.sifra !== '') &&
            (this.state.radnaVisina !== '') &&
            (this.state.radniDoseg !== '') &&
            (this.state.podizniKapacitet !== '') &&
            (this.state.dimenzije !== '') &&
            (this.state.tezina !== '')
        ) {



            if(this.state.url) {

                axios.post('https://webrentsite.firebaseio.com/teleskopskiVilicari/teleskopski/items.json', {

                img: this.state.url,
                naziv: this.state.naziv,
                sifra: this.state.sifra,
                link: this.state.sifra.replace(/ /g,'').toLowerCase(),
                pogon: this.state.pogon ? this.state.pogon.replace(/ /g,'') : '',
                radnaVisina: parseFloat(this.state.radnaVisina).toFixed(2),
                radniDoseg: parseFloat(this.state.radniDoseg).toFixed(2),
                podizniKapacitet: parseFloat(this.state.podizniKapacitet).toFixed(2),
                dimenzije: this.state.dimenzije,
                platforma: this.state.platforma ? this.state.platforma : '',
                tezina: parseFloat(this.state.tezina).toFixed(2),
                pdf: ''


                })
                .then(response => {

                    console.log(response.data)

                })
                .catch(error => {
            
                });  

                this.setState({
                    image: null, 							 
		            url: '', 								
		            percentage: null, 
                    naziv: '',
                    sifra: '',
                    pogon: '',
                    radnaVisina: '',
                    radniDoseg: '',
                    podizniKapacitet: '',
                    dimenzije: '',
                    platforma: '',
                    tezina: ''
                })

            }
            


        }
        

    }

    render() {   

        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3, // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2, // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1, // optional, default to 1.
            },
          };
        

        return (
            <section className="Main">
                
                <Platforms 
                    selectedValues={this.props.selectedValues}
                />

                <Blocks />

                <Industry 
                    industryClickHandler={this.props.industryClickHandler} 
                    industry={this.props.industry} 
                    IndustryTemplateTitle={this.props.IndustryTemplateTitle}
                    IndustryTemplateText={this.props.IndustryTemplateText}
                    IndustryTemplateImg={this.props.IndustryTemplateImg}
                />

                <Brands />               

                <Whyus />

            </section>
        );
    } 
    

    

}

export default Main;


/*

 <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay
                    autoPlaySpeed={2000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 2000,
                            min: 1024
                          },
                          items: 3,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 1,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        }
                      }}
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={2}
                      swipeable
                    >
                        <WithStyles
                            image={dieci}
                            link="https://www.dieci.com/web/en/dealer-network/"
                        />

                        <WithStyles
                            image={haulotte}
                            link="https://www.haulotte.co.uk/"
                        />

                        <WithStyles
                            image={faraone}
                            link="https://faraone.it/en/"
                        />

                        <WithStyles
                            image={easylift}
                            link="https://easy-lift.com/en/"
                        />

                        <WithStyles
                            image={fiorentini}
                            link="http://fiorentinispa.com/it/homepage"
                        />

                        <WithStyles
                            image={zoomlion}
                            link="http://en.zoomlion.com/"
                        />

                        <WithStyles
                            image={comet}
                            link="https://www.officinecomet.it/en/"
                        />

                        <WithStyles
                            image={lift}
                            link="https://2lift.com/"
                        />
                    </Carousel>

*/