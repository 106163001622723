import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'


class Nav extends Component {


    state={
        items:[
            {name: 'Najam', link: '/najam', path: 'najam'},
            {name: 'Prodaja rabljeno', link: '/prodaja-rabljeno', path: 'prodaja-rabljeno'},
            {name: 'Prodaja novo', link: '/prodaja-novo', path: 'prodaja-novo'},
            {name: 'Obuka', link: '/obuka', path: 'obuka'},
            {name: 'Stručnost', link: '/strucnost', path: 'strucnost'},
            {name: 'O Up Rent-u', link: '/o-nama', path: 'o-nama'},
            {name: 'Kontakt', link: '/kontakt', path: 'kontakt'}
        ]
    }



    render() {

        let list = this.props.items.map(i => {
            return(
                    <NavLink to={i.link} activeClassName='is-active' key={i.name} className="NavItem">
                        {i.name ? i.name : <img src={i.img} alt={i.name}/>}
                    </NavLink>
            )
        })

        return (
            <div className="NavWrap">
            <nav className="Nav">
                
                    {list}

            </nav>
            </div>
        );
    } 
    

    

}

export default Nav;
