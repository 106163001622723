import React, {Component} from 'react'







class WithStyles extends Component {




    render() {
        return (
            <div className="WithStyles" style={{backgroundImage:"url(" + this.props.image + ")"}} className="Slide">
                
            <div className="SlideBox">
               <h2>
                   {   
                      this.props.headline 
                   }
               </h2>
               <p>{this.props.description}</p>
               <button style={{display: this.props.link ? 'block' : 'none'}}><a href={this.props.link} style={{textDecoration: 'none', color: 'black'}}>{this.props.btn}</a></button> 
            </div>

            </div>
        );
    } 
    

    

}

export default WithStyles;
