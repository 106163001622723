import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'

import logo from '../../assets/images/logo.png'
import phone from '../../assets/images/phone.svg'

class TopHeader extends Component {




    render() {

        let img = null

        if(window.innerWidth < 500){

            
            img = null

        } 

        if(window.innerWidth > 500){

            img = <img src={phone} alt='phone' />

        } 



        return (
            <div className="TopHeader">
                
                    <p style={{fontSize: 'small', marginBottom: '0', textAlign: 'center'}}>NAJAM • PRODAJA • SERVIS • TRANSPORT</p>

                    <div className="TopHeaderWrap">

                        <div className="Logo">

                            <div className="LogoImg">
                            <NavLink to="/">
                                <img src={logo} alt='logo' />
                            </NavLink>
                            </div>

                            <div className="LogoText">
                                
                                <h4>
                                    
                                    <a href="tel:+385 1 24 55 000" style={{textDecoration: 'none', color:'black'}}>+385 1 24 55 000</a>
                                </h4>
                                {img}
                            </div>

                        </div>

                        <div className="Lang">
                        </div>

                    </div>


            </div>
        );
    } 
    

    

}

export default TopHeader;
