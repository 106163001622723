import React, {Component} from 'react'



import obukaImg from '../../../assets/images/obukaImg.jpg'
import sinisa from '../../../assets/images/sinisa.jfif'


class Obuka extends Component {




    render() {
        return (
            <div className="Obuka">
                
                <div style={{backgroundImage:"url(" + obukaImg + ")"}} className="ObukaCover"></div>

                <div className="ObukaWrap">
                    <div className="ObukaText">
                        <h1>EDUKACIJA</h1>

                        <p>Kako je riječ o poslu visokoga rizika ne možemo dovoljno naglasiti važnost osiguravanja sigurnih uvjeta za obavljanje tih vrsta radova, a samim time i sprečavanje ozljeda i nesreća uzrokovanih manjkom adekvatne opreme. Od iznimnog je značenja zaposlenicima pružiti zaštitu na radu koja omogućava kvalitetno i sigurno obavljanje ovih vrsta djelatnosti što dokazuje velik broj nesreća i ozljeda jer godišnje u RH na poslu izgubi život oko 50 radnika, dok oko 1000 radnika doživi težu povredu. Najveći broj nezgoda na radu dogodi se upravo u šumarstvu i građevini.</p>

                        <p>
                        Prema člancima 96., 97. i 98. „Zakona o zaštiti na radu“, poslodavac je dužan zaposlenicima pružiti posebne uvjete rada, upute za rad na siguran način te zaštitnu radnu opremu potrebnu za obavljanje radova, ali i spriječiti rizike na radu i to uklanjajući čimbenike rizika i njihovih štetnih posljedica. Ukoliko poslodavac to nije učinio, za prekršaje ovoga Zakona, može se izreći novčana kazna u iznosu od 1.900,00 do čak 120.000,00 kn.
                        </p>

                        <p>
                        Neminovna je činjenica da radovi na visini zaposlenike izlažu opasnosti zbog čega je nužno poduzeti sve moguće mjere kako bi se spriječile nesreće i ozljede na radu. Korištenje platformi osigurava zaposlenike u skladu sa „Zakonom o zaštiti na radu“ te ujedno optimizira vrijeme potrebno za izvršenje Vaših poslova i zadataka.
                        Vjerujemo da ćete biti zadovoljni našom uslugom jer poslujemo u skladu s najvišim normama i standardima te činimo sve kako bismo udovoljili svim zahtjevima naših klijenata u nadi da ćemo i u budućnosti uspješno surađivati na obostrano zadovoljstvo.
                        </p>

                        <h4>
                        CIJENA 670 kn + PDV po polazniku – PRIJAVITE SE!
                        </h4>
                    </div>

                    <div className="ObukaPerson">
                        <img src={sinisa} />
                        <h3>Imate pitanja?</h3>
                        <h4>Kontaktirajte nas!</h4>
                        <p>
                        <a href={"mailto:sinisa@uprent.hr"} style={{textDecoration: 'none', color: 'black'}}>sinisa@uprent.hr </a>
                        </p>
                        <p>
                            <a href="tel:+385913320040" style={{textDecoration: 'none', color: 'black'}}>+385 91 332 0040</a>
                        </p>
                    </div>
                </div>

            </div>
        );
    } 
    

    

}

export default Obuka;
