import React, {Component} from 'react'
import firebase from '../../../firebase/firebase'
import 'firebase/firestore';

//import mail from '../../../assets/images/mail.svg'
//import phone from '../../../assets/images/phone.svg'


class Kontakt extends Component {


    state={
        people: [
            {name: 'Marcijan', department: 'Najam platformi', mail: 'najam@uprent.hr', phone: '+385 91 332 9000'},
            {name: 'Luka', department: 'Prodaja platformi, viličara i auto košara', mail: 'prodaja@uprent.hr', phone: '+385 91 332 9006'},
            {name: 'Siniša', department: 'Direktor', mail: 'sinisa@uprent.hr', phone: '+385 91 332 0040'},
            {name: 'Ratka', department: 'Računovodstvo i financije', mail: 'iva@uprent.hr', phone: '+385 99 300 3111'},
            {name: 'Tomislav', department: 'Servis platformi i viličara', mail: 'servis@uprent.hr', phone: '+385 91 332 9001'},
            {name: 'Info', department: 'Info', mail: 'info@uprent.hr', phone: '+385 1 24 55 000'}

        ],

        trShow: true,

        name: '',
        num: '',
        subject: '',    
        email: '',
        msg: '',
        err: false
    }

    componentDidMount() {


        if(window.innerWidth < 500){

            this.setState({
                trShow: false
            })

        } 

        if(window.innerWidth > 500){

            this.setState({
                trShow: true
            })

        } 

    }

    changeHandler = (e) => {

        this.setState({[e.target.name]:e.target.value})                   // odabrani name njegov value

        if(
            (this.state.name !== '') &&
            (this.state.subject !== '') &&
            ((this.state.email !== '') ||
            (this.state.num !== '')) &&
            (this.state.msg !== '') &&
            (this.state.err === true)
        ) {
            this.setState({err: false})
        }
    
    
      }

    submitHandler = (e) => {
    
        e.preventDefault()          // slanje value - email adrese se filtrirane i onda stavljene u const emails i onda postavljene kao name unutar submit btna i onda kad se klikne                                  on postavi state i taj state se salje u node

        const db = firebase.firestore();
        db.settings({
            timestampsInSnapshots: true
        });

        if(
            (this.state.name !== '') &&
            (this.state.subject !== '') &&
            ((this.state.email !== '') ||
            (this.state.num !== '')) &&
            (this.state.msg !== '')
        ) {

            const userRef = db.collection("mails").add({
                name: this.state.name,
                num: this.state.num,
                subject: this.state.subject,
                email: this.state.email,
                msg: this.state.msg
            });  
    
            setTimeout(() => {
                this.setState({msg: '', subject: '', email: '', name: '', num: ''})
            }, 700)

        } else {
            this.setState({
                err: true
            })
        }

    
      }


    render() {


        let people = this.state.people.map(p => {
            return (
                <tr key={p.phone}>
                    <td style={{display: this.state.trShow ? 'block' : 'none'}}>{p.department}</td>
                    <td>{p.name}</td> 
                    <td><a href={"mailto:" + p.mail + ""} >{p.mail}</a></td>
                    <td><a href={'tel:' + p.phone}>{p.phone}</a></td>
                </tr>
            )
        })

        return (
            <div className="Kontakt">
                


                <div className="KontaktText">
                    <h1>KONTAKTIRAJTE NAS!</h1>
                    <p>Ukoliko ste zainteresirani za neki od naših proizvoda ili usluga, ili Vam je potreban određeni savjet, slobodno nas kontaktirajte telefonski ili putem emaila. Naš tim stručnjaka uvijek je spreman pomoći i pronaći adekvatno rješenje za Vaš problem.</p>
                    <h4>Nalazimo se na adresi: Radnička cesta 184, Zagreb</h4>
                </div>

                <div className="KontaktBox">
                    <table>
                        <thead>
                            <tr>
                                <th style={{display: this.state.trShow ? 'block' : 'none'}}>Odjel</th>
                                <th>Ime</th>
                                <th>Email</th>
                                <th>Mobitel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {people}
                        </tbody>
                    </table>
                </div>

                <div className="FormWrap">
                    <form onSubmit={this.submitHandler}>
                    <p style={{display: this.state.err ? 'block' : 'none', color: '#c50c26'}}>Provjerite jeste upisali sve obvezne podatke.</p>
                    <p>Sve označeno sa * je obvezno.</p>
                        <div className="FormDiv">
                            <input className="formInput" placeholder="Ime i prezime*" onChange={(e) => this.changeHandler(e)} name="name" value={this.state.name}/> <br />
                            <input className="formInput" placeholder="Predmet*" onChange={(e) => this.changeHandler(e)} name="subject" value={this.state.subject}/> <br />
                            <input className="formInput" placeholder="Email*" onChange={(e) => this.changeHandler(e)} name="email" value={this.state.email} /> <br />
                            <input className="formInput" type="number" placeholder="Broj" onChange={(e) => this.changeHandler(e)} name="num" value={this.state.num}/> <br />
                            <p>Poruka:*</p>
                            <textarea className="InputElement" onChange={(e) => this.changeHandler(e)} name="msg" value={this.state.msg}></textarea> <br />
                            <button type="submit" >Pošalji</button>
                        </div>
                    </form>
                </div>

            </div>
        );
    } 
    

    

}

export default Kontakt;
