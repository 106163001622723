import React, {Component} from 'react'

import {Link} from 'react-router-dom'


import dieci from '../../../assets/images/dieci.png'
import haulotte from '../../../assets/images/haulotte.png'
import faraone from '../../../assets/images/faraone.png'
import easylift from '../../../assets/images/easylift.png'
import fiorentini from '../../../assets/images/fiorentini.png'
import zoomlion from '../../../assets/images/zoomlion.png'
import comet from '../../../assets/images/comet.png'
import lift from '../../../assets/images/lift.png'
import katoImer from '../../../assets/images/katoImer.png'



class Brands extends Component {


    state={
        brands:[
            {id: 0, img: dieci, link: 'https://www.dieci.com/web/en/dealer-network/'},
            {id: 1, img: haulotte, link: 'https://www.haulotte.it/distributeurs'},
            {id: 2, img: faraone, link: 'https://www.elevah.com/en/home'},
            {id: 3, img: easylift, link: 'https://easy-lift.com/en/'},
            {id: 4, img: fiorentini, link: 'http://fiorentinispa.com/it/homepage'},
            {id: 5, img: zoomlion, link: 'https://www.zoomlion-europe.com/'},
            {id: 6, img: comet, link: 'https://www.officinecomet.it/en'},
            {id: 7, img: lift, link: 'https://2lift.com/'},
            {id: 8, img: katoImer, link: 'https://www.katoimer.com/jsp/Template1/HomePage.jsp'}
        ]
    }




    render() {

        	let brands = this.state.brands.map(b =>{
                return(
                    <a href={b.link} key={b.id}>
                        <div key={b.id} className="Brand">
                            <img src={b.img} />
                        </div>
                    </a>
                )
            })

        return (
            <div className="Brands">

                <h2 style={{textAlign: 'center'}}>GENERALNI PARTNERI</h2>

                <div className="BrandsWrap">
                
                    {brands}

                </div>

            </div>
        );
    } 
    

    

}

export default Brands;
