import React, {Component} from 'react'

import TopHeader from './TopHeader'
import Nav from './Nav'
import Slider from './Slider'



class Header extends Component {




    render() {

        return (
            <header className="Header">
                
                <TopHeader />
                <Nav items={this.props.items}/>
                <Slider />

            </header>

        );
    } 
    

    

}

export default Header;
