import React, {Component} from 'react'


import app from '../../../assets/images/app.jpg'



class Blocks extends Component {



    state={

        blocks: [
            {img: app, title: 'Mobilna aplikacija', text: '', link: 'https://play.google.com/store/apps/details?id=hr.uprent'},
            //{img: machine, title: 'Up Rent video', text: 'Zašto UpRent?', link: '/uprent-video'},
           // {img: elevate, title: 'Međunarodni najam', text: '', link: '/medunarodni'},
        ]

    }



    render() {


        let blocks = this.state.blocks.map(b => {
            return(
                <div className="Block" key={b.title}>
                    <a href={b.link} key={b.title} >
                        <div>
                        <img src={b.img} />
                        <h4>{b.title}</h4>
                        <p>{b.text}</p>
                        </div>
                    </a>
                </div>
            )
        })


        return (
            <div className="Blocks">
                
                <div className="BlocksDiv">
                
                    {blocks}

                </div>

            </div>
        );
    } 
    

    

}

export default Blocks;
